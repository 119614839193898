import React from 'react';
import {store, RenderApp} from '../../index';
import App from './AdSet';
//import {getAdsetInsights,getAds} from "../../common/marketingAPI";
import {getAdsetInsights,getAdset} from "../../common/marketingAPI/adsetInfo";



async function fetchAdsetInsights(adset_id:string){
    store.dispatch({type:"SET_INTERVAL",interval:0});
    
    await getAdset(adset_id);

    const statCards = document.querySelectorAll('.stat-card');

    for(let i = 0; i < statCards.length; i++){
        statCards[i].classList.add('loading');
    }

    await getAdsetInsights(adset_id,0);
    
    for(let i = 0; i < statCards.length; i ++){
        statCards[i].classList.remove('loading');
    }    

    RenderApp();

    return null;
}


async function fetchInsights(){
    const interval = store.getState().dashboard.interval;
    const adset_id = store.getState().adset.adsetInfo.id;//TODO change to adset id
    await getAdsetInsights(adset_id,interval);
    RenderApp();

    return null
}


export default function(){
    return <App info={store.getState().adset.adsetInfo} fetchInsights={fetchInsights} fetchAdsetInsights={fetchAdsetInsights}></App>;
}