import React from 'react';
import {store} from '../../index';
import App from './AdSetList';

function getMoreAdSets(){
    console.log("retrieving more adsets");
    return null;
}

export default function(){
    return <App posts={store.getState().adset.adsets} getAdSets={getMoreAdSets}></App>;
}