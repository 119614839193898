import {db,store} from '../../index';
import { faUsers, faVideo, faHandPointUp, faShareAlt, faEye, faComment, faLink, faThumbsUp} from '@fortawesome/free-solid-svg-icons';


export async function getAdset(adset_id:string){
    //First get campaign
    await db.collection('adsets').doc(adset_id).get().then(async (doc:any) => {
        
        const adsetInfo:any = doc.data();
        adsetInfo.data = [{
            name:"Visningar",
            value: (adsetInfo.impressions ? adsetInfo.impressions : 0),
            icon:faEye,
            tooltip:"Totalt antal visningar. Kan inkludera flertalet visningar av samma person."
        },{
            name:"Unika Visningar",
            value: (adsetInfo.reach ? adsetInfo.reach : 0),
            icon:faUsers,
            tooltip:"Antal personer som sett annonserna åtminstone en gång."
        },
        {
            name:"Klick",
            value: (adsetInfo.clicks ? adsetInfo.clicks  : 0),
            icon:faHandPointUp,
            tooltip:"Det totala antalet klick på annonsen."
        },{
            name:"CTR",
            value: (adsetInfo.ctr ? adsetInfo.ctr : 0),
            icon:faShareAlt,
            tooltip:"Procent personer som såg annonsen och klickade."
        }];

        //get campaign name and image
        await db.collection('campaigns').doc(adsetInfo.campaign_id).get().then(async (doc:any) => {
            const campaignData:any = doc.data();

            adsetInfo.thumbnail_url = campaignData.thumbnail_url;
            adsetInfo.campaign_name = campaignData.custom_name ? campaignData.custom_name : campaignData.name;

            store.dispatch({type: "SET_HERO_BG", background_url: campaignData.thumbnail_url});

            return null;
        });

        store.dispatch({type: "ADSET_INFO", adsetInfo: adsetInfo});//Store object

        //Get ads
        await db.collection('adsets').doc(adset_id).collection('ads').get().then((querySnapshot:any) => {

            const ads:any = {
                data:[],
                next:null,
            }

            querySnapshot.forEach((doc:any) => {
                ads.data.push(doc.data());
            });
    
            store.dispatch({type: "SET_ADS", ads: ads});//Store object
    
    
            return null;
        });

        return null;
    });

    return null;
}



export async function getAdsetInsights(id:string, interval:number){

    //Fetch latest data
    await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/adset_insights?id=${id}&interval=${interval}`).then(async (response:any)=>{

        return response.json();

    }).then((json)=>{

        let graph_insights;

        if(typeof(json.graph_insights) === "string"){
            graph_insights = JSON.parse(json.graph_insights);
        }else{
            graph_insights = json.graph_insights;
        }

        const adsetInfo:any = store.getState().adset.adsetInfo;


        adsetInfo.data = [{
            name:"Visningar",
            value: json.impressions,
            icon:faEye,
            tooltip:"Totalt antal visningar. Kan inkludera flertalet visningar av samma person.",
            graph_data:(graph_insights ? graph_insights.impressions : []),
        },{
            name:"Unika Visningar",
            value: json.reach,
            icon:faUsers,
            tooltip:"Antal personer som sett annonserna åtminstone en gång.",
            graph_data:(graph_insights ? graph_insights.reach : []),
        },/*{
            name:"Klick",
            value: json.clicks,
            icon:faHandPointUp,
            graph_data:(graph_insights ? graph_insights.clicks : []),
            tooltip:"Det totala antalet klick på annonsen."
        },*/{
            name:"Klick",
            value: json.link_clicks,
            icon:faLink,
            tooltip:"Antalet klick som gått till en annan länkdestination, både inom och utanför Facebook.",
            graph_data:(graph_insights ? graph_insights.link_clicks : []),
        },{
            name:"Inläggsreaktioner",
            value: json.post_reaction,
            icon:faThumbsUp,
            tooltip:"Antalet gilla-markeringar annonsen fått.",
            graph_data:(graph_insights ? graph_insights.post_reaction : []),
        },{
            name:"Kommentarer",
            value: json.comment,
            icon:faComment,
            tooltip:"Antalet kommentarer på annonsen.",
            graph_data:(graph_insights ? graph_insights.comment : []),
        },{
            name:"CTR",
            value: json.ctr,
            icon:faShareAlt,
            tooltip:"Procent personer som såg annonsen och klickade.",
            graph_data:(graph_insights ? graph_insights.ctr : []),
        }];

        if(json.unique_video_view_15_sec){
            adsetInfo.data.push({
                name:"Videovisningar",
                value: json.unique_video_view_15_sec,
                icon:faVideo,
                tooltip:"Antal unika personer som sett videon i minst 15 sekunder.",
                graph_data:graph_insights.unique_video_view_15_sec,
            });
        }

        store.dispatch({type: "ADSET_INFO", adsetInfo: adsetInfo});//Store object

    }).catch((error:any)=>{
        const adsetInfo:any = store.getState().adset.adsetInfo;

        adsetInfo.data = [{
            name:"Totala Visningar",
            value: -1,
            icon:faEye,
            tooltip:"Totalt antal visningar. Kan inkludera flertalet visningar av samma person.",
            graph_data:[],
        },{
            name:"Unika Visningar",
            value: -1,
            icon:faUsers,
            tooltip:"Antal personer som sett annonserna åtminstone en gång.",
            graph_data:[],
        },{
            name:"Klick",
            value: -1,
            icon:faLink,
            tooltip:"Antalet klick som gått till en annan länkdestination, både inom och utanför Facebook.",
            graph_data:[],
        },{
            name:"Inläggsreaktioner",
            value: -1,
            icon:faThumbsUp,
            tooltip:"Antalet gilla-markeringar annonsen fått.",
            graph_data:[],
        },{
            name:"Kommentarer",
            value: -1,
            icon:faComment,
            tooltip:"Antalet kommentarer på annonsen.",
            graph_data:[],
        },{
            name:"CTR",
            value: -1,
            icon:faShareAlt,
            tooltip:"Procent personer som såg annonsen och klickade.",
            graph_data:[],
        }];

        store.dispatch({type: "ADSET_INFO", adsetInfo: adsetInfo});//Store object
        return null;
    });

}

