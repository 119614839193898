import React from "react";
import {Route,Switch,Redirect} from "react-router-dom";
import Footer from "./Footer/Footer";
import LoginComponent from './LoginComponent';
import CreateAccount from './LoginComponent/CreateAccount';
import ForgotPassword from './LoginComponent/ForgotPassword';
import PrivacyPolicy from './privacyComponent';

function PublicComponent(props:any){
    return(
        <>
            <Switch>
                <Route path="/login" component={LoginComponent} />
                <Route path="/privacypolicy" component={PrivacyPolicy} />
                <Route path="/createaccount" component={CreateAccount} />
                <Route path="/resetpassword" component={ForgotPassword} />
                <Route render={() => <Redirect to="/login" />} />
            </Switch>
            <Footer></Footer>
        </>
    );
}


export default PublicComponent;