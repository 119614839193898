import React from 'react';
import { withRouter } from 'react-router-dom';
import ScrollToTop from "../ScrollTop";

function App(props:any){

    const goBack = () => {
        if(props.history){
            //going back regular style
            props.history.goBack();
        }else{
            //no history
            props.history.push("/dashboard")
        }
    }

  return(
      <>
        <ScrollToTop></ScrollToTop>
        <article className="text-wrapper">
            <h1>Integritetspolicy</h1>
            <p>Vi kommer spara uppgifter om:</p>
            <ul className="list">
                <li>Din företagsemail</li>
                <li>Namn och efternamn</li>
                <li>Din arbetsplats</li>
            </ul>
            <p>Dessa uppgifter kommer tillåta oss att filtrera ut så att statistik för annonserna du har behörighet till visas.</p>
            <p>Om ni vill att uppgifterna om er ska tas bort vänligen kontakta <a className="button-text" href="mailto:info@tross.se">info@tross.se</a></p>

            <button onClick={(e)=>{e.preventDefault(); goBack();}} className="button goback-button">Gå tillbaka</button>
        </article>
    </>
  );

}


export default withRouter(App);
