import React, { useEffect,useState } from 'react';
import AdSetList from '../AdSetList';
import Hero from '../HeroComponent';
import {withRouter,Link} from "react-router-dom";
import ScrollToTop from "../ScrollTop";

function CampaignInsights(props:any){

    const [loading,setLoading] = useState(true);

    useEffect(()=>{

        const getCampaignInsights = async () =>{

            if(props.match.params.id){//Fetch if id in url
                    //Don't fetch if content is already loaded
                    await props.getCampaignInfo(props.match.params.id);
                    setLoading(false);
                    //await props.fetchCampaignInsights();
                    //await props.getAdSets(props.match.params.id);
            }        
        }

        getCampaignInsights();

    },[]);

    return(
        <>
            <ScrollToTop></ScrollToTop>
            <main>
                
                <>
                    <div className="breadcrumbs-wrapper">
                        <p className="breadCrumbs">
                            <span><Link to="/dashboard/">Överblick</Link></span><span>{loading ? "Laddar kampanj..." : (props.info ? (props.info.custom_name ? props.info.custom_name : props.info.name) : "Inget resultat")}</span>
                            {/*<span><Link to="/dashboard/">Överblick</Link></span><span>{loading ? "Laddar..." : (props.info ? (props.info.custom_name ? props.info.custom_name : props.info.name) : "Inget resultat")}</span>*/}
                        </p>
                    </div>
                    <Hero info={props.info} fetchStats={props.fetchCampaignInsights} loading={loading}/>
                    <AdSetList/>
                    <div className="wrapper">
                        <button className="button" onClick={(e)=>{e.preventDefault(); props.history.goBack()}}>
                            Gå tillbaka
                        </button>
                    </div>
                </>
                
            </main>
        </>
    );
}


export default withRouter(CampaignInsights);
