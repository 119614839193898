import React,{useEffect,useState} from 'react';
import Hero from '../HeroComponent';
import AdList from '../AdList';
import {withRouter,Link} from "react-router-dom";
import ScrollToTop from "../ScrollTop";

function AdSetComponent(props:any){
  const [loading,setLoading] = useState(true);


  useEffect(()=>{

    const getAdsetInsights = async () =>{
        await props.fetchAdsetInsights(props.match.params.id);
        setLoading(false);
    }

    getAdsetInsights();

},[]);

  return(
    <>
      <ScrollToTop></ScrollToTop>
      <main>
          <>
            <div className="breadcrumbs-wrapper">
              <p className="breadCrumbs">
                <span><Link to="/dashboard/">Överblick</Link></span>
                {props.info && <span><Link to={'/dashboard/campaign/'+props.info.campaign_id}>{props.info.campaign_name}</Link></span>}
                {/*<span>{loading ? "Laddar..." : (props.info ? props.info.name : "Inget resultat")}</span>*/}
                <span>{loading ? "Laddar Ad set..." : (props.info ? "Ad set" : "Inget resultat")}</span>
              </p>
            </div>
            <Hero info={props.info} fetchStats={props.fetchInsights} loading={loading}/>
            <AdList/>
            <div className="wrapper">
                <button className="button" onClick={(e)=>{e.preventDefault(); props.history.goBack()}}>
                    Gå tillbaka
                </button>
            </div>
          </>
      </main>
    </>
  );
}


export default withRouter(AdSetComponent);