const defaultState = {
    adsets:{
        data:[],
        next:"",
    },
    adsetInfo:null,
};

export default function(state = defaultState, action:any){

    if(action.type === "SET_ADSETS"){
        return { ...state, adsets: action.adsets };
    }

    if(action.type === "ADSET_INFO"){
        return { ...state, adsetInfo: action.adsetInfo };
    }

    return state;
};