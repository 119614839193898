import React,{useState} from 'react';
import {formatDate} from '../../common/formatHelper';
import {Link, withRouter} from "react-router-dom";
import Message404 from "../Message404";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { Manager, Reference, Popper } from 'react-popper';
import "./style.scss";

function CampaignList(props:any){

    const [loading,setLoading] = useState(false);

    const getCampaigns = async () => {
        setLoading(true);
        await props.getCampaigns();
        setLoading(false);
    }

    return(
        <>
        <div className="list-container-wrapper">
            <div className="list-container">
                <div className="list-title">
                    Tidigare Kampanjer
                </div>
                <div className="list-overflow-wrapper">
                    <div className="list-content">
                        <div className="list-header">
                            <div className="list-item-wrapper">
                                {props.isAdmin && <span className="item width-fixed-100px"></span>}
                                <span className="item width-fixed-150px">Id</span>
                                <span className="item width-fixed-150px">Namn</span>
                                <span className="item width-auto">Beställare</span>
                                <span className="item width-fixed-120px">Status</span>
                                <span className="item width-fixed-120px">Antal annonser</span>
                                <span className="item width-fixed-120px">Startdatum</span>
                                <span className="item width-fixed-120px">Slutdatum</span>
                                <span className="item width-fixed-175px"></span>
                            </div>
                        </div>
                        {props.posts.data.length > 0 && 
                        <ul className="list-body">
                            {
                            props.posts.data.map((post:any,index:number)=>{
                                return(
                                    <React.Fragment key={index}>
                                        {(!post.hidden || props.isAdmin) &&
                                            <ListItem post={post}  history={props.history} isAdmin={props.isAdmin} hideCampaign={props.hideCampaign}></ListItem>
                                        }
                                    </React.Fragment>
                                );
                            })
                            }
                        </ul>
                        }
                    </div>
                </div>
                {props.posts.data.length === 0 && <Message404 message={"Inga tidigare kampanjer hittades"} />}
            </div>
            {props.nextBatch && 
                <button className={"button" + (loading ? " loading" : "")} onClick={(e)=>{e.preventDefault(); getCampaigns();}}>
                    Ladda fler
                </button>
            }
        </div>
        </>
    );
}
  
  
function ListItem(props:any){
    const currentDate = new Date();
    const stopDate = new Date(props.post.stop_time);

    let link = `/dashboard/campaign/${props.post.id}`;

    if(props.post.adsets.length === 1){
      link = `/dashboard/adset/${props.post.adsets[0]}`;
      if(props.post.ads.length === 1){
        link = `/dashboard/advert/${props.post.ads[0]}`;
      }
    }

    return(
        <li className="list-item filter_element">
            <div className="list-item-wrapper">
                {props.isAdmin &&
                    <div className="item width-fixed-100px">
                        <HideAdButton id={props.post.id} hideCampaign={props.hideCampaign} hidden={props.post.hidden}></HideAdButton>
                    </div>
                }
                <div className="item width-fixed-150px"><span className="filter_variable">{props.post.id}</span></div>
                <div className="item width-fixed-150px">
                    <Link to={link}>
                        <button className="button-text">
                            <span className="filter_variable">{props.post.name}</span>
                        </button>
                    </Link>
                </div>
                <div className="item width-auto"><span className="filter_variable">{props.post.ordered_by}</span></div>
                <div className="item width-fixed-120px">
                    <span className={"status " + (currentDate.getTime() > stopDate.getTime() ? "": (props.post.status)) }>
                        {(currentDate.getTime() > stopDate.getTime() ? "Avklarad": 
                            <>
                                {props.post.status === "ACTIVE" && "Aktiv"}
                                {props.post.status === "UNCOMPLETE" && "Ej aktiv"}
                                {props.post.status === "DONE" && "Avklarad"}
                            </>
                        )}
                    </span>
                </div>
                <div className="item width-fixed-120px">{props.post.ads.length}</div>
                <div className="item width-fixed-120px">{formatDate(new Date(props.post.start_time))}</div>
                <div className="item width-fixed-120px">{formatDate(new Date(props.post.stop_time))}</div>
                <div className="item width-fixed-175px">
                    <Link to={link}>
                        <button className="button go-to-button">
                            Gå till kampanj <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    </Link>
                </div>
            </div>
        </li>
    );
}

function HideAdButton(props:any){
    const [hidden,setHidden] = useState((props.hidden ? props.hidden : false));
  
    const revealAd = () => {
      setHidden(false);
      props.hideCampaign(props.id,false);
    }
  
    const hideAd = () => {
      setHidden(true);
      props.hideCampaign(props.id,true);
    }
  
    return(
        <span className="tooltip-container">
            <Manager>
            <Reference>
                {({ ref }) => (
                    <span ref={ref}>
                    {hidden ? 
                        <button className="no-bg hide-button reveal" onClick={(e)=>{e.preventDefault(); revealAd()}}>
                        <FontAwesomeIcon icon={faEye}/>
                        </button> :
                        <button className="no-bg hide-button hide" onClick={(e)=>{e.preventDefault(); hideAd();}}>
                        <FontAwesomeIcon icon={faEyeSlash}/>
                        </button>
                    }
                    </span>
                )}
            </Reference>
            <Popper placement="left">
                {({ ref, style, placement, arrowProps }) => (
                <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                    <div className="tooltip">
                    <span className="text">{hidden ? "Visa kampanj" : "Göm kampanj"}</span>
                    </div>
                    <div ref={arrowProps.ref} style={arrowProps.style} />
                </div>
                )}
            </Popper>
        </Manager>
    </span>
    );
  }  


export default withRouter(CampaignList);