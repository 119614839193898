import React from 'react';
import {store, dbAuth, RenderApp, storage, db} from '../../index';
import App from './headerComponent';

function updateLoginState(){
    store.dispatch({type:"LOGGED_IN",isLoggedIn:false});
    store.dispatch({type:"EMAIL",email:""});
    store.dispatch({type:"USER_INFO",userInfo:{}});
    store.dispatch({type: "SET_ACTIVE_CAMPAIGNS", activeCampaigns: []});//Store object
    store.dispatch({type: "SET_CAMPAIGNS", campaigns: {
        data:[],
        next:"",
    }});//Store object
    
    RenderApp();

    /*
    store.dispatch({type: "POST_DATA", info: []});
    store.dispatch({type: "POST_ID", id: ""});
    store.dispatch({type: "SET_OVERVIEW", info: []});
    store.dispatch({type: "SET_INTERVAL", interval: 30});


    localStorage.removeItem('email');*/

}

async function getLogo(){
    return store.getState().login.userInfo ? store.getState().login.userInfo.logo_url : null;
}


export default function(){
    return <App state={store.getState()} updateLoginState={updateLoginState} getLogo={getLogo}></App>;
}