import React,{useEffect,useRef,useState} from 'react';
import StatComponent from '../statComponent/statComponent';
import DateInterval from '../dateIntervalComponent';
import {formatDate} from '../../common/formatHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUsers, faUserCircle, faShareAlt, faEye, faClock, faLink, faThumbsUp, faComment} from '@fortawesome/free-solid-svg-icons';
import "./style.scss";
import { Manager, Reference, Popper } from 'react-popper';
import { POINT_CONVERSION_COMPRESSED } from 'constants';

function Hero(props:any){

    const [daysLeft,setDaysLeft] = useState(-1);
    const imageRef:any = useRef();

    const cards404 = [{
        name:"Totala Visningar",
        value: -1,
        icon:faEye,
        tooltip:"Totalt antal visningar. Kan inkludera flertalet visningar av samma person."
    },{
        name:"Unika Visningar",
        value: -1,
        icon:faUsers,
        tooltip:"Antal personer som sett annonserna åtminstone en gång."
    },/*{
        name:"Klick",
        value: -1,
        icon:faHandPointUp,
        tooltip:"Det totala antalet klick på annonsen."
    },*/{
        name:"Klick",
        value: -1,
        icon:faLink,
        tooltip:"Antalet klick som gått till en annan länkdestination, både inom och utanför Facebook.",
    },{
        name:"Inläggsreaktioner",
        value: -1,
        icon:faThumbsUp,
        tooltip:"Antalet gilla-markeringar annonsen fått.",
    },{
        name:"Kommentarer",
        value: -1,
        icon:faComment,
        tooltip:"Antalet kommentarer på annonsen.",
    },{
        name:"CTR",
        value: -1,
        icon:faShareAlt,
        tooltip:"Procent personer som såg annonsen och klickade."
    }];


    useEffect(()=>{
      if(props.background_url !== ""){
        var newImg = new Image;
        newImg.onload = function() {
          imageRef.current.classList.add("reveal-bg");
        }
        newImg.src = props.background_url;
      }
    },[props.background_url]);

    useEffect(()=>{

      if(!props.info){
        return;
      }

      var date1;
      //If the campaign hasnt begun yet display the time that is left on the start and stop time interval
      if(new Date() < new Date(props.info.start_time)){
        date1 = new Date(props.info.start_time); 
      }else{
        date1 = new Date(); 
      }
      
      var date2 = new Date(props.info.stop_time); 
        
      // To calculate the time difference of two dates 
      var Difference_In_Time = date2.getTime() - date1.getTime(); 
        
      // To calculate the no. of days between two dates 
      var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24)); 

      if(Difference_In_Days < 0){
        setDaysLeft(0);
      }else{
        setDaysLeft(Difference_In_Days);
      }

    },[props.info]);


    return(
      <div className="hero">
        {props.background_url && props.background_url !== "" &&
            <div  className="hero-background" 
                style={{background:'url(' + props.background_url + ') no-repeat center center / cover'}} ref={imageRef}></div>
        }
        {props.info ? 
          <>
            <div className="wrapper wrapper-1200">
              <div className="top-container">
                <span className="title-container">
                  <span className="title">
                    <h1>{props.info.custom_name ? props.info.custom_name : props.info.name}</h1>
                    {/*<div className={"status " + props.info.status}>
                      {props.info.status === "ACTIVE" && "aktiv"}
                      {props.info.status === "UNCOMPLETE" && "ej aktiv"}
                      {props.info.status === "DONE" && "avklarad"}
                    </div>*/}
                  </span>
                  {props.info.created_time && 
                  <span className="info-container">
                    <span className="title-info">
                      <span>ID {props.info.id}</span>
                    </span>
                    <div className="date tooltip-container">
                      <Manager>
                        <Reference>
                          {({ ref }) => (
                              <span ref={ref}>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                              </span>
                          )}
                        </Reference>
                        <Popper placement="right">
                          {({ ref, style, placement, arrowProps }) => (
                            <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                              <div className="tooltip tooltip-right">
                                <span className="text">{"Startdatum " + formatDate(new Date(props.info.start_time))}</span>
                                <span className="text">{"Slutdatum " + formatDate(new Date(props.info.stop_time))}</span>
                              </div>
                            </div>
                          )}
                        </Popper>
                      </Manager>
                    </div>
                    {props.info.ordered_by &&
                    <div className="date tooltip-container ordered-by">
                      <Manager>
                        <Reference>
                          {({ ref }) => (
                              <span ref={ref}>
                                <FontAwesomeIcon icon={faUserCircle}/>
                              </span>
                          )}
                        </Reference>
                        <Popper placement="right">
                          {({ ref, style, placement, arrowProps }) => (
                            <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                              <div className="tooltip tooltip-right">
                                <span className="text">Beställd av {props.info.ordered_by}</span>
                              </div>
                            </div>
                          )}
                        </Popper>
                      </Manager>
                    </div>}
                  </span>
                } 
                </span>
                <DateInterval fetchStats={props.fetchStats} id={props.info.id}></DateInterval>
              </div>
      
              <ul className="grid-container">
                {props.info.data &&
                  props.info.data.map((stat:any,index:number)=>{
                    return(
                      <li key={index}>
                          <StatComponent stat={stat}></StatComponent>
                      </li>
                    );
                  })
                }
                <li>
                  <StatComponent stat={{
                      name:"Dagar kvar",
                      value: daysLeft,
                      icon:faClock,
                      tooltip:"Dagar tills annonskampanjen är över."
                  }}></StatComponent>
                </li>
              </ul>
            </div>
          </>
        : 
        <>
            <div className="wrapper wrapper-1200">
              <div className="top-container">
                <span className="title-container">
                  <h1>{props.loading ? "Laddar..." : "Inget resultat"}</h1>
                </span>
              </div>
      
              <ul className="grid-container">
                {cards404.map((stat:any,index:number)=>{
                    return(
                      <li key={index}>
                          <StatComponent stat={stat}></StatComponent>
                      </li>
                    );
                  })
                }
                <li>
                  <StatComponent stat={{
                      name:"Dagar kvar",
                      value: daysLeft,
                      icon:faClock,
                      tooltip:"Dagar tills annonskampanjen är över."
                  }}></StatComponent>
                </li>
              </ul>
            </div>
          </>
        }
      </div>
    );
}

export default Hero;