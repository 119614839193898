import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Link} from "react-router-dom";
import { validateEmail } from '../../../common/emailValidation';
import {dbAuth} from '../../../index';
import "../style.scss";

function App(props:any){
    const loginRef:any = useRef();
    const emailErrorRef:any = useRef();
    const infoMessage:any = useRef();

    const [email,setEmail] = useState("");
    const [emailError,setEmailError] = useState(false)

    const sendNewPassword = () => {

        //Basic validation
        if(!email){
            emailErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setEmailError(true);
            return;
        }else if(!validateEmail(email)){
            emailErrorRef.current.innerHTML = "Ogiltig mailaddress";
            setEmailError(true);
            return;
        }

        loginRef.current.classList.add("loading");

        dbAuth.sendPasswordResetEmail(email).then(function() {
            // Email sent.
            loginRef.current.classList.remove("loading");
            infoMessage.current.classList.add("success");

        }).catch(function(error:any) {
            // An error happened.
            loginRef.current.classList.remove("loading");

            if(error.code === "auth/network-request-failed"){
                setEmailError(true);
                emailErrorRef.current.innerHTML = "Kunde inte koppla nå nätverket.";
            }else{
                emailErrorRef.current.innerHTML = "Något gick fel";
                setEmailError(true);
            }

        });
    }

    useEffect(()=>{
        setEmailError(false);
    },[email]);

    return(
        <>
            <div className="login-container">
                <h1>Glömt lösenord</h1>
                <form className="form" onSubmit={(e) => {e.preventDefault(); sendNewPassword()}}>
                    <div className={"input-wrapper" + (emailError ? " error" :"")}>
                        <input placeholder="Email" type="text" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message info-message"  ref={infoMessage}>
                            <p>Ett återställningsmail har skickats!</p>
                        </div>
                        <div className="message error-message" >
                            <p ref={emailErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <button className="button" type="submit" ref={loginRef}>
                        Skicka återställningsmail
                    </button>
                </form>
                <div className="login-footer">
                    <p>Tillbaks till inloggning? <Link to="/login"><button className="button-text">Logga in</button></Link></p>
                </div>
            </div>
        </>
    );

}


export default withRouter(App);
