import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Link} from "react-router-dom";
import {validateEmail} from '../../../../common/emailValidation';
import {dbAuth} from '../../../../index';
import "./style.scss";

function App(props:any){
    const loginRef:any = useRef();
    const emailErrorRef:any = useRef();
    const pwErrorRef:any = useRef();

    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError,setEmailError] = useState(false);
    const [pwError,setPwError] = useState(false);


    useEffect(()=>{

        dbAuth.onAuthStateChanged(async function(user:any) {
            if (user) {//Set state to logged in if user already is and fetch statistics        

                const isAdmin = await props.checkIfAdmin(user.email);
         
                if(isAdmin){
                    props.updateLoginState(user.email);
                    props.history.push('/admin/dashboard/');
                    return;
                }else{
                    dbAuth.signOut().then(function() {
                        // Sign-out successful.
                    }).catch(function(error:any) {
                        // An error happened.
                    });
                }

           
            }
        });
    
    },[]);



    const login = async () => {

        let error = false;

        if(!email){
            //fieldError("emailField","Fältet får inte vara tomt");
            emailErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setEmailError(true);
            error = true;
        }else if(!validateEmail(email)){
            //fieldError("emailField","Ogiltig mailaddress");
            emailErrorRef.current.innerHTML = "Ogiltig mailaddress";
            setEmailError(true);
            error = true;
        }
    
        if(!password){
            //fieldError("passwordField","Fältet får inte vara tomt");
            pwErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setPwError(true);
            error = true;
        }
    
        if(error){
            return;
        }

        loginRef.current.classList.add("loading");

        
        const isAdmin = await props.checkIfAdmin(email);
         
        if(!isAdmin){
            loginRef.current.classList.remove("loading");
            pwErrorRef.current.innerHTML = "Du har inte behörighet för denna sidan";
            setPwError(true);
            return;
        }


        dbAuth.signInWithEmailAndPassword(email.trim(),password.trim()).then(async function() {
            // Sign-in successful
            props.updateLoginState(email);
            props.history.push('/admin/dashboard/');
                
        }).catch(function(error:any){
            if(!loginRef.current){
                return;
            }

            loginRef.current.classList.remove("loading");

            if(error.code === "auth/network-request-failed"){
                setPwError(true);
                pwErrorRef.current.innerHTML = "Kunde inte koppla nå nätverket.";
            }else{
                setPwError(true);
                pwErrorRef.current.innerHTML = "Ogiltiga kontouppgifter";
            }
        });

    }

    useEffect(()=>{
        setEmailError(false);
    },[email]);

    useEffect(()=>{
        setPwError(false);
    },[password]);


    return(
        <>
            <div className="login-container">
                <h1>Inloggning - Admin</h1>
                <form className="form" onSubmit={(e) => {e.preventDefault(); login()}}>
                    <div className={"input-wrapper" + (emailError ? " error" : "")}>
                        <input placeholder="Email" type="text" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message error-message">
                            <p ref={emailErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <div className={"input-wrapper" + (pwError ? " error" : "")}>
                        <input placeholder="Lösenord" type="password" value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message error-message">
                            <p ref={pwErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <button className="button" type="submit" ref={loginRef}>
                        Logga in
                    </button>
                </form>
            </div>
        </>
    );

}



export default withRouter(App);
