import React from 'react';

function Message404(props:any){
    return(
  
      <div className="container-404">
        <h4>{props.message}</h4>
      </div>
  
    );
}

export default Message404;