import React, {useState,useRef} from "react";
import "./style.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Search(props:any){
    const inputField:any = useRef();
    const [input,setInput] = useState("");

    const search = (newInput:string) => {
        props.filter(newInput);
    }

    return(
        <>
            <div className="search" onClick={()=>{
                inputField.current.focus();
            }}>
                <form className="search__form" onSubmit={(e)=>{e.preventDefault(); search(e.currentTarget.value);}} autoComplete="off">
                    <input className="search__input" ref={inputField} placeholder="Filtrera kampanjer (namn, id)" value={input} onChange={(e)=>{setInput(e.currentTarget.value); search(e.currentTarget.value);} }/>
                    <span className="search__icon"><FontAwesomeIcon icon={faSearch}/></span>
                </form>
            </div>
        </>
    );    
}

export default Search;