import React from 'react';
import {store, RenderApp} from '../../index';
import App from './AdInsights';
import {createPDF} from '../../common/createPdf';
import {getAdInsights, getAdInfo} from '../../common/marketingAPI/adInfo';


async function fetchInsights(ad_id:string){

    store.dispatch({type: "SET_TARGET", target: null});//Store object
    store.dispatch({type: "AD_INFO", adInfo: null});//Store object
    RenderApp();

    store.dispatch({type:"SET_INTERVAL",interval:0});
    await getAdInfo(ad_id);


    const statCards = document.querySelectorAll('.stat-card');

    for(let i = 0; i < statCards.length; i++){
        statCards[i].classList.add('loading');
    }

    await getAdInsights(ad_id,0);
    
    for(let i = 0; i < statCards.length; i ++){
        statCards[i].classList.remove('loading');
    }    

    RenderApp();

    return null;

}


async function fetchAdInsights(){
    const interval = store.getState().dashboard.interval;
    const ad_id = store.getState().ad.adInfo.id;
    await getAdInsights(ad_id,interval);
}


export default function(){
    return <App info={store.getState().ad.adInfo}
                target={store.getState().ad.target}
                adPreviews={store.getState().ad.adPreview} 
                fetchStats={fetchAdInsights} 
                fetchInsights={fetchInsights}
                createPDF={createPDF}/>;
}
