import React, { useState, useEffect, useRef } from 'react';
import Hero from '../HeroComponent';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import {withRouter,Link} from "react-router-dom";
import ScrollToTop from "../ScrollTop";

function AdInsights(props:any){
    const [loading,setLoading] = useState(true);
    const imageRef:any = useRef();

    useEffect(()=>{

        const getAdInsights = async () =>{
            await props.fetchInsights(props.match.params.id);
            setLoading(false);
        }
    
        getAdInsights();
    
    },[]);

    useEffect(()=>{

        if(!props.info){
            return;
        }
        if(!props.info.image_url){
            return;
        }

        if(props.info.image_url === ""){
            return;
        }

        var newImg = new Image;
        newImg.onload = function() {

            if(imageRef.current){
                imageRef.current.classList.add("reveal-image");
            }
        }
        newImg.src = props.info.image_url;

      },[props.info]);

    return(
      <>
      <ScrollToTop></ScrollToTop>
      <main>
        <>
            <div className="breadcrumbs-wrapper">
                <p className="breadCrumbs">
                    <span><Link to="/dashboard/">Överblick</Link></span>
                    {props.info && 
                        <>
                        <span><Link to={"/dashboard/campaign/"+props.info.campaign_id}>{props.info.campaign_name}</Link></span>
                        <span><Link to={"/dashboard/adset/"+props.info.adset_id}>{"Ad set"}</Link></span>
                        {/*<span><Link to={"/dashboard/adset/"+props.info.adset_id}>{props.info.adset_name}</Link></span>*/}
                    </>}
                    <span>{loading ? "Laddar Annons..." : (props.info ? "Annons" : "Inget resultat")}</span>
                </p>
                </div>
                <Hero info={props.info} fetchStats={props.fetchStats} loading={loading}/>
                <div className="wrapper">
                <div className="ad-preview-container">
                    {/*props.adPreviews && 
                    props.adPreviews.map((preview:any,index:number)=>{
                        return(
                        <div className="ad-preview" key={index} dangerouslySetInnerHTML={{__html:preview.body}} />
                        );
                    })
                    */}

                    {props.info &&
                        props.info.image_url && <a href={props.info.image_url} rel="noopener noreferrer" target='_blank'><img ref={imageRef} className="ad-image" src={props.info.image_url} /></a>
                    }

                    {props.target && 
                    <div className="target_container">
                        {/*props.target.map((current_target:any,index:number)=>{
                        return(
                            <div key={index}>
                                <span className="title">{current_target.content === "Location:" ? "Plats" : (current_target.content === "Age:" ? "Åldersgrupp" : current_target.content.substring(0,current_target.content.length - 1))} </span> 
                                <ul className="target-info">
                                    {current_target.children.map((child:any,index:number)=>{
                                    return(
                                        <li key={index}>{child}</li>
                                    );
                                    })}
                                </ul>
                            </div>
                        );
                        })*/}
                        <div className="link-container">
                            {props.info.preview_shareable_link && <a href={props.info.preview_shareable_link} rel="noopener noreferrer" target='_blank' className='text-link'>Länk till förhandsvisning</a>}
                            {props.info.instagram_permalink_url && <a href={props.info.instagram_permalink_url} rel="noopener noreferrer" target='_blank' className='text-link'>Länk till Instagram</a>}
                        </div>
                    </div>
                    }
                </div>
                <div className="wrapper actions">
                    <button className="button" onClick={(e)=>{e.preventDefault(); props.history.goBack()}}>
                        Gå tillbaka
                    </button>
                    <button className="button-text pdf-button" onClick={(e) => {e.preventDefault(); props.createPDF()}}>
                        <FontAwesomeIcon icon={faFileAlt} />
                        <span className="text">Skapa PDF</span>
                    </button>
                </div>
            </div>
        </>
      </main>
      </>
    );
  }


  export default withRouter(AdInsights);