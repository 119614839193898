import React,{useRef,useEffect,useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import {dbAuth} from '../../index';
import { withRouter, Link} from "react-router-dom";
import "./style.scss";

function App(props:any){
  const headerRef:any = useRef();
  const logoRef:any = useRef();
  const user = props.state.login.email;
  const [logo,setLogo] = useState();


  useEffect(()=>{

    /*if(!headerRef.current){
      return;
    }*/

    const getLogo = async () => {
      const url = await props.getLogo();
        
      if(url){
        var newImg = new Image;

        newImg.onload = () => {
            setLogo(url);
            logoRef.current.classList.add("reveal");
        }
        
        newImg.src = url;
      }else{
        logoRef.current.classList.add("reveal");
      }

      
    }

    getLogo();

    /*
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function() {

        if(!headerRef){
          return;
        }

        var currentScrollPos = window.pageYOffset;
        if (prevScrollPos > currentScrollPos) {
          if(headerRef.current){
            headerRef.current.classList.remove("hide");
          }
        }else{
          if(currentScrollPos > 57){
            if(headerRef.current){
              headerRef.current.classList.add("hide");
            }
          }
        }
        prevScrollPos = currentScrollPos;
    }*/

  },[]);


  const signOut = () => {

    dbAuth.signOut().then(function() {
      // Sign-out successful.
      props.updateLoginState();

      props.history.push("/login");

    }).catch(function(error:any) {
      // An error happened.
      //Kunde inte logga ut
  });

  }


  return(
    <>
      <header className="ghost-element">
        <div className="wrapper">
            <button className="logo header-button">
              <img src={"/assets/logo.png"} alt="logo"></img>
            </button>
            <nav>
                <ul>
                    <li>
                        <button className="header-button">
                          <span>{user}</span>
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                        <span className="header-pulldown">
                          <ul>
                              <li>
                                <button className="list-button">
                                  <FontAwesomeIcon icon={faSignOutAlt} /> Logga ut
                                </button>
                              </li>
                          </ul>   
                        </span> 
                    </li>
                </ul>
            </nav>
        </div>
      </header>
      <header ref={headerRef}>
        <div className="wrapper">
            <Link to="/dashboard/">
              <button className="logo header-button">
                <img ref={logoRef} src={logo ? logo : "/assets/logo.png"} alt="logo"></img>
              </button>
            </Link>
            <nav>
                <ul>
                    <li>
                        <button className="header-button">
                          <span className="text">{user}</span>
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                        <span className="header-pulldown">
                          <ul>
                              <li>
                                <button className="list-button" onClick={(e) => {e.preventDefault(); signOut();}}>
                                  <FontAwesomeIcon icon={faSignOutAlt} /> Logga ut
                                </button>
                              </li>
                          </ul>
                        </span>    
                    </li>
                </ul>
            </nav>
        </div>
      </header>
    </>

  );

}

export default withRouter(App);
