import React from 'react';
import {store, RenderApp, db, storage} from '../../index';
import App from './Search';


function filter(input:string = ""){

    const filter = input.toUpperCase();
    const li:any = document.querySelectorAll('.filter_element');
    
    if(li.length === 0){
        return;
    }

    // Loop through all list items, and hide those who don't match the search query
    for (let i = 0; i < li.length; i++) {
        const variables:any = li[i].querySelectorAll('.filter_variable');
        
        let matchesFilter = false;

        for(let j = 0; j < variables.length; j++){
            let txtValue = variables[j].textContent || variables[j].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                matchesFilter = true;
            }
        }

        if(matchesFilter){
            li[i].classList.remove("filter_hidden");
        }else{
            li[i].classList.add("filter_hidden");
        }

    }

    const container:any = document.querySelector(".active-container");
    const obj404 = document.querySelector(".container-404");
    

    const filterElements = container.querySelectorAll(".filter_element");
    const filterHidden = container.querySelectorAll(".filter_hidden");

    if(filterElements.length - filterHidden.length === 0){
        if(obj404){
            obj404.classList.add("visible");
        }
    }else{
        if(obj404){
            obj404.classList.remove("visible");
        }
    }

    /*if(container){
        container.style.transform = `translateX(0px)`;
    }*/

}


export default function(){
    return <App state={store.getState()} filter={filter}></App>;
}