import React from 'react';
import App from './ActiveCards';
import {db,store,RenderApp} from '../../index';


async function saveName(campaignId:string,newName:string){
    
    const response = await db.collection("campaigns").doc(campaignId).set({
        custom_name:newName,
    },{ merge: true }).then(function() {
        return true;
    }).catch(function(error:any) {
        return false;
    });


    if(response){//If save successfull loop through campaigns and update value
        const activeCampaigns = store.getState().campaign.activeCampaigns;

        for(let i = 0; i < activeCampaigns.length; i++){
            if(activeCampaigns[i].id === campaignId){
                activeCampaigns[i].custom_name = newName;
                break;
            }
        }

        store.dispatch({type: "SET_ACTIVE_CAMPAIGNS", activeCampaigns: activeCampaigns});//Store object
        RenderApp();
    }


    return response;

}


export default function(props:any){
    return <App activeCampaigns={props.activeCampaigns} saveName={saveName} isAdmin={store.getState().login.userInfo ? store.getState().login.userInfo.admin : false} hideCampaign={props.hideCampaign}></App>;
}