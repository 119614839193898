import React from 'react';
import {store} from '../../index';
import App from './dateIntervalComponent';

function setInterval(interval:number){
    store.dispatch({type:"SET_INTERVAL",interval:interval});
}

export default function(props:any){
    return <App fetchStats={props.fetchStats} state={store.getState()} setInterval={setInterval}></App>;
}