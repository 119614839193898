import React from "react";
import "./style.scss";
import {store, db, storage} from '../../../../index';
import App from './CompanyDetails';


async function getUsers(company_id:string){

    const users:any = await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/get_users?company_id=${company_id}`).then((response)=>{
        return response.json();
    }).then((json:any)=>{
        return json;
    }).catch((error:any)=>{
        return null;
    });

    return users;

}


async function setBlacklistStatus(email:string,blacklist:boolean){

    await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/disable_user?email=${email}`).then((response)=>{
        return response.json();
    }).then((json:any)=>{
        return json;
    }).catch((error:any)=>{
        return null;
    });


    const response = await db.collection("users").doc(email).set({
        email:email,
        blacklisted: blacklist,
    },{ merge: true }).then(function() {
        console.log("Document successfully written!");
        return true;
    }).catch(function(error:any) {
        console.error("Error writing document: ", error);
        return false;
    });

    return response;
}


async function getCompanyInfo(company_id:string){

    const company_info = await db.collection("companies").doc(company_id).get().then((docSnapshot:any)=>{
        return docSnapshot.data();
    });

    return company_info;
}

async function getLogo(company_id:string){
    const logo = await storage.ref("logos/" + company_id + ".png").getDownloadURL().then(function(url:any) {
        return url;
    }).catch((error:any)=>{
        return null;
    });
    return logo;
}

async function deleteLogo(company_id:string){
    // Delete the file
    const deleteResponse = await storage.ref("logos/" + company_id + ".png").delete().then(function() {
        // File deleted successfully
        return true;
    }).catch(function(error:any) {
        // Uh-oh, an error occurred!
        return false;
    });

    return deleteResponse;
}

async function saveName(company_id:string,name:string){
    // Add a new document in collection "cities"
    const response = await db.collection("companies").doc(company_id).set({
        name: name,
    },{ merge: true }).then(function() {
        console.log("Document successfully written!");
        return true;
    }).catch(function(error:any) {
        console.error("Error writing document: ", error);
        return false;
    });

    return response;
}

async function updatePriviledge(email:string,permission:boolean){
    
    const response = await db.collection("users").doc(email).set({
        permission: permission,
    },{ merge: true }).then(function() {
        console.log("Document successfully written!");
        return true;
    }).catch(function(error:any) {
        console.error("Error writing document: ", error);
        return false;
    });

    return response;
}

export default function(){
    return <App state={store.getState()} getUsers={getUsers} 
                getCompanyInfo={getCompanyInfo} 
                getLogo={getLogo} 
                saveName={saveName} 
                setBlacklistStatus={setBlacklistStatus} 
                deleteLogo={deleteLogo}
                updatePriviledge={updatePriviledge}></App>;
}