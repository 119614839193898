import React from 'react';
import {store, RenderApp} from '../../../../index';
import App from './LoginComponent';


async function checkIfAdmin(email:string){

    const response = await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/check_if_admin?email=${email}`).then((response)=>{
        return response.json();
    }).then((json)=>{
        return json;
    });

    return response.isAdmin;
}

function updateLoginState(email:string){
    store.dispatch({type:"LOGGED_IN",isLoggedIn:true});
    store.dispatch({type:"EMAIL",email:email});
    RenderApp();
}

export default function(){
    return <App state={store.getState()} updateLoginState={updateLoginState} checkIfAdmin={checkIfAdmin}></App>;
}