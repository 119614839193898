import React from "react";
import {Link} from "react-router-dom";
import "./style.scss";

function Footer(){
    const date = new Date();
  
    return(
      <footer className="footer">
        <div className="footer__wrapper">
          <div className="footer__company">
            <a href="https://tross.se/" rel="noopener noreferrer" target="_blank"><img className="footer__logo" src="/assets/tross-logo-vit.png" alt="Tross logo"></img></a>
            <small>© {date.getFullYear()} TROSS Sverige AB</small>
          </div>
          <div className="footer__privacy">
            <small><Link className="footer__link" to="/dashboard/privacy/">Integritetspolicy</Link></small>
          </div>
          <div className="footer__contact">
            <h3>Kontakt</h3>
            <small>
              <span className="footer__title">Email</span><a href="mailto:media@tross.se" className="footer__link">media@tross.se</a>
            </small>
            <small>
              <span className="footer__title">Växel</span><a href="tel:036-172960" className="footer__link">036-17 29 60</a>
            </small>
          </div>
        </div>
      </footer>
    );
}

export default Footer;