import React from 'react';
import {store, RenderApp} from '../../index';
import App from './CampaignInsights';
//import {getCampaignInsights} from "../../common/marketingAPI";
import {getCampaignInfo,getCampaignInsights} from "../../common/marketingAPI/campaignInfo";

async function getCampaignInformation(campaignId:string){
    store.dispatch({type:"SET_INTERVAL",interval:0});
    await getCampaignInfo(campaignId);

    const statCards = document.querySelectorAll('.stat-card');

    for(let i = 0; i < statCards.length; i++){
        statCards[i].classList.add('loading');
    }

    await getCampaignInsights(campaignId,0);

    for(let i = 0; i < statCards.length; i ++){
        statCards[i].classList.remove('loading');
    }    

    RenderApp();

    return null;
}

async function fetchCampaignInsights(){
    const interval = store.getState().dashboard.interval;
    const campaign_id = store.getState().campaign.campaignInfo.id;

    await getCampaignInsights(campaign_id,interval);
    RenderApp();

    return null;
}


export default function(props:any){
    return <App info={store.getState().campaign.campaignInfo} 
                getCampaignInfo={getCampaignInformation}
                fetchCampaignInsights={fetchCampaignInsights} 
                //getAdSets={getAdSets}
            ></App>;
}