import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {take} from 'redux-saga/effects';

import loginReducer from './components/LoginComponent/loginReducer';
import dashboardReducer from './components/Dashboard/dashboardReducer';
import campaignReducer from './components/CampaignList/CampaignReducer';
import adsetReducer from './components/AdSetList/AdSetReducer';
import adListReducer from './components/AdList/AdListReducer';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB9F-QqeS0owuNKbsIikFKKA9CQgCA42SY",
    authDomain: "tross-ad-platform.firebaseapp.com",
    databaseURL: "https://tross-ad-platform.firebaseio.com",
    projectId: "tross-ad-platform",
    storageBucket: "tross-ad-platform.appspot.com",
    messagingSenderId: "62600006743",
    appId: "1:62600006743:web:8e3bcc849e205811",
    measurementId: "G-JK0QSKCC4C"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const dbAuth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const dbRef = firebase.database().ref();

const rootReducer = combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    adset: adsetReducer,
    campaign: campaignReducer,
    ad: adListReducer,
});


const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(actionSaga);

//TODO
function* actionSaga(){
    while(true){
        const action = yield take();
        void action;
    }
}



firebase.auth().onAuthStateChanged(async function(user:any) {
    if (user) {//Set state to logged in if user already is and fetch statistics

        if(user.emailVerified){
            const button = document.getElementById("loginButton");
            if(button){
                button.classList.add("loading");
            }
            store.dispatch({type:"EMAIL",email:user.email});
            store.dispatch({type:"LOGGED_IN",isLoggedIn:true});
            RenderApp();
        }else{
            store.dispatch({type:"LOGGED_IN",isLoggedIn:false});
        }        

    } else {//Set logged in to false
        store.dispatch({type:"LOGGED_IN",isLoggedIn:false});
    }
});
 


/*
//Login if email and password is stored in localStorage
if(localStorage.getItem('email')){
    const email = localStorage.getItem('email');
    store.dispatch({type: "EMAIL", email: email});
}
*/


export function RenderApp(){
 
    ReactDOM.render(
        <App state={store.getState()} store={store}/>, document.getElementById('root')
    );
    
}

RenderApp();