import {db,store} from '../../index';
import { faUsers, faVideo, faShareAlt, faEye, faComment, faLink, faThumbsUp} from '@fortawesome/free-solid-svg-icons';


async function checkIfAdmin(email:string){

    /*const response = await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/check_if_admin?email=${email}`).then((response)=>{
        return response.json();
    }).then((json)=>{
        return json;
    });

    return response.isAdmin;*/

   const response = await db.collection('admins').doc(email).get().then((querySnapshot:any) => {
        return querySnapshot.exists;
    }).catch(()=>{
        return null;
    });

    return response;
}

export async function getCampaigns(){

    const userEmail = store.getState().login.email;

    const activeCampaigns:any = [];
    const campaigns:any = {
        data:[],
        next:null,
    }

    let myQuery:any;

    const isAdmin = await checkIfAdmin(userEmail);

    if(isAdmin){//Display all posts if admin
        myQuery = db.collection('campaigns')
            .orderBy("created_time", "desc")
            .limit(20);
    }else{//Only show user specific adverts

        if(store.getState().login.userInfo && store.getState().login.userInfo.company_id){//Check if user have a company id
            if(store.getState().login.userInfo.permission){//Display all posts from organisation if have permissions
                myQuery = db.collection('campaigns')
                .where("business","==",store.getState().login.userInfo.company_id).limit(20);
            }else{
                myQuery = db.collection('campaigns').where("ordered_by","==",userEmail).limit(20); //.where("hidden","==",false)
            }
        }else{
            return;
        }

    }
    

    const fetchSuccessful = await myQuery.get().then((querySnapshot:any) => {
        if(isAdmin){//Check if user have a company id
            querySnapshot.forEach((doc:any) => {
                campaigns.data.push(doc.data());
            });

        }else{
            querySnapshot.forEach((doc:any) => {
                if(!doc.data().hidden){//Only show ads that aren't hidden
                    campaigns.data.push(doc.data());
                }
            });

        }


        const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]

        const nextBatch = myQuery.startAfter(lastVisible);

        store.dispatch({type: "NEXT_BATCH", nextBatch: nextBatch});//Store object

    }).then(()=>{

        const campaignsList = [...campaigns.data];

        const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    
        let activeCounter = 0;
    
        for(let i = 0; i < campaignsList.length; i++){
    

            //If they are active do an aditional api call to get more info
            if(new Date(campaignsList[i].stop_time) > beforeOneWeek){
                
                campaigns.data.splice(i-activeCounter++,1);//Remove from campaign list, since it's displayed in the card view instead
                activeCampaigns.push(campaignsList[i]);
                
            }
        }

        if(activeCampaigns.length === 0){
            let activeCounter = 0;

            for(let i = 0; i < campaigns.data.length; i++){
                if(i === 6){
                    break;
                }
                
                campaigns.data.splice(i-activeCounter++,1);//Remove from campaign list, since it's displayed in the card view instead
                activeCampaigns.push(campaignsList[i]);
            }
        }

    
        store.dispatch({type: "SET_HERO_BG", background_url: ""});
    
        store.dispatch({type: "SET_ACTIVE_CAMPAIGNS", activeCampaigns: activeCampaigns});//Store object
        store.dispatch({type: "SET_CAMPAIGNS", campaigns: campaigns});//Store object


        return true;
    }).catch((error:any)=>{
        
        return false;

    });


    return fetchSuccessful;

}



export async function getNextCampaignBatch(){

    const nextBatch = store.getState().campaign.nextBatch;

   if(!nextBatch){//Check if exists
        return;
    } 

    const myQuery = nextBatch;

    const fetchSuccessful = await myQuery.get().then((querySnapshot:any) => {
        
        const campaigns = store.getState().campaign.campaigns;

        querySnapshot.forEach((doc:any) => {
            campaigns.data.push(doc.data());
        });

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]
        let nextBatch = null;
        
        if(lastVisible !== undefined){
            nextBatch = myQuery.startAfter(lastVisible);
        }


        store.dispatch({type: "NEXT_BATCH", nextBatch: nextBatch});//Store object

        store.dispatch({type: "SET_CAMPAIGNS", campaigns: campaigns});//Store object

    })

    return fetchSuccessful;

}


export async function getCampaignInsights(id:string, interval:number){

    //Fetch latest data
    await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/campaign_insights?id=${id}&interval=${interval}`).then(async (response:any)=>{
        return response.json();

    }).then((json:any)=>{
        let graph_insights;

        if(typeof(json.graph_insights) === "string"){
            graph_insights = JSON.parse(json.graph_insights);
        }else{
            graph_insights = json.graph_insights;
        }

        const campaignInfo:any = store.getState().campaign.campaignInfo;

        campaignInfo.data = [{
            name:"Visningar",
            value: json.impressions,
            icon:faEye,
            tooltip:"Totalt antal visningar. Kan inkludera flertalet visningar av samma person.",
            graph_data:graph_insights.impressions,
        },{
            name:"Unika Visningar",
            value: json.reach,
            icon:faUsers,
            tooltip:"Antal personer som sett annonserna åtminstone en gång.",
            graph_data:graph_insights.reach,
        },
        /*{
            name:"Klick",
            value: json.clicks,
            icon:faHandPointUp,
            graph_data:graph_insights.clicks,
            tooltip:"Det totala antalet klick på annonsen."
        },*/{
            name:"Klick",
            value: json.link_clicks,
            icon:faLink,
            tooltip:"Antalet klick som gått till en annan länkdestination, både inom och utanför Facebook.",
            graph_data:(graph_insights ? graph_insights.link_clicks : []),
        },{
            name:"Inläggsreaktioner",
            value: json.post_reaction,
            icon:faThumbsUp,
            tooltip:"Antalet gilla-markeringar annonsen fått.",
            graph_data:(graph_insights ? graph_insights.post_reaction : []),
        },{
            name:"Kommentarer",
            value: json.comment,
            icon:faComment,
            tooltip:"Antalet kommentarer på annonsen.",
            graph_data:(graph_insights ? graph_insights.comment : []),
        },{
            name:"CTR",
            value: json.ctr,
            icon:faShareAlt,
            tooltip:"Procent personer som såg annonsen och klickade.",
            graph_data:graph_insights.ctr,
        }];


        if(json.unique_video_view_15_sec){
            campaignInfo.data.push({
                name:"Videovisningar",
                value: json.unique_video_view_15_sec,
                icon:faVideo,
                tooltip:"Antal unika personer som sett videon i minst 15 sekunder.",
                graph_data:graph_insights.unique_video_view_15_sec,
            });
        }

        store.dispatch({type: "CAMPAIGN_INFO", campaignInfo: campaignInfo});//Store object

    }).catch((error:any)=>{
        const campaignInfo:any = store.getState().campaign.campaignInfo;

        campaignInfo.data = [{
            name:"Totala Visningar",
            value: -1,
            icon:faEye,
            tooltip:"Totalt antal visningar. Kan inkludera flertalet visningar av samma person.",
            graph_data:[],
        },{
            name:"Unika Visningar",
            value: -1,
            icon:faUsers,
            tooltip:"Antal personer som sett annonserna åtminstone en gång.",
            graph_data:[],
        },/*{
            name:"Klick",
            value: -1,
            icon:faHandPointUp,
            graph_data:[],
            tooltip:"Det totala antalet klick på annonsen."
        },*/{
            name:"Klick",
            value: -1,
            icon:faLink,
            tooltip:"Antalet klick som gått till en annan länkdestination, både inom och utanför Facebook.",
            graph_data:[],
        },{
            name:"Inläggsreaktioner",
            value: -1,
            icon:faThumbsUp,
            tooltip:"Antalet gilla-markeringar annonsen fått.",
            graph_data:[],
        },{
            name:"Kommentarer",
            value: -1,
            icon:faComment,
            tooltip:"Antalet kommentarer på annonsen.",
            graph_data:[],
        },{
            name:"CTR",
            value: -1,
            icon:faShareAlt,
            tooltip:"Procent personer som såg annonsen och klickade.",
            graph_data:[],
        }];

        store.dispatch({type: "CAMPAIGN_INFO", campaignInfo: campaignInfo});//Store object
        return null;
    });

}

export async function getCampaignInfo(campaign_id:string){
    //First get campaign
    await db.collection('campaigns').doc(campaign_id).get().then(async (doc:any) => {
        const campaignInfo:any = doc.data();

        store.dispatch({type: "SET_HERO_BG", background_url: campaignInfo.thumbnail_url});

        store.dispatch({type: "CAMPAIGN_INFO", campaignInfo: campaignInfo});//Store object

        //Then retrieve adsets
        await db.collection('campaigns').doc(campaign_id).collection('adsets').get().then((querySnapshot:any) => {

            const adsets:any = {
                data:[],
                next:null,
            }

            querySnapshot.forEach((doc:any) => {
                adsets.data.push(doc.data());
            });
    
            store.dispatch({type: "SET_ADSETS", adsets: adsets});//Store object
    
    
            return null;
        });

        return null;
    });

    return null;
    
}
