import React from 'react';
import {store} from '../../index';
//import {getAds} from "../../common/marketingAPI";
import App from './AdList';

function getMoreAds(){
    console.log("Retrieving ads")
}

export default function(){
    return <App posts={store.getState().ad.ads} getAds={getMoreAds} ></App>;
}