
const defaultState = {
    email: "",
    isLoggedIn: false,
    userInfo: {}
};

export default function(state = defaultState, action:any){

    if(action.type === "EMAIL"){
        return { ...state, email: action.email };
    }

    if(action.type === "LOGGED_IN"){
        return { ...state, isLoggedIn: action.isLoggedIn };
    }

    if(action.type === "USER_INFO"){
        return {...state, userInfo: action.userInfo};
    }

    return state;
};