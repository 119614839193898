import React from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router} from "react-router-dom";
import LoginComponent from "./components/LoginComponent";
import Dashboard from './components/Dashboard';
import Details from './components/CompanyDetails';
import UsersManagement from './components/AllUsers';
import HeaderComponent from "./components/headerComponent";
import "./style.scss";
import Footer from "../Footer/Footer";

function App(props:any){
    return(
        <>
            <Switch>
                <Route exact path="/admin/login" component={LoginComponent} />
                <PrivateRoute path="/admin/" component={Admin} isLoggedIn={props.state.login.isLoggedIn}/>
                <Route render={() => <Redirect to="/admin/login" />} />
            </Switch>
            <Footer></Footer>
        </>
    );
}


function PrivateRoute({component: Component, isLoggedIn, ...rest}:any){
    return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
    isLoggedIn ?
        <Component {...props} />
    : <Redirect to="/admin/login" />
    )} />
    );
};


function Admin(props:any){
    return(
        <>
            <HeaderComponent></HeaderComponent>
            <Switch>
                <Route path="/admin/users/" component={UsersManagement} />
                <Route path="/admin/details/:id" component={Details} />
                <Route path="/admin/" component={Dashboard} />
            </Switch>
        </>
    );
}


export default App;
