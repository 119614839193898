
const defaultState = {
    name: "",
    interval: 30,
    info:[],
    background_url:"",
};

export default function(state = defaultState, action:any){

    if(action.type === "NAME"){
        return { ...state, name: action.name };
    }

    if(action.type === "SET_INTERVAL"){
        return { ...state, interval: action.interval};
    }

    if(action.type === "SET_OVERVIEW"){
        return { ...state, info: action.info};
    }

    if(action.type === "SET_HERO_BG"){
        return { ...state, background_url: action.background_url};
    }

    return state;
};