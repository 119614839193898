import React from 'react';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {formatNumber} from '../../common/formatHelper';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import "./style.scss";
import { Manager, Reference, Popper } from 'react-popper';

function App(props:any){

    return(
  
        <div className="stat-card grid-card">
          {props.stat.tooltip &&
            <span className="info tooltip-container">
              <Manager>
                <Reference>
                  {({ ref }) => (
                      <span ref={ref}>
                        <FontAwesomeIcon icon={faInfo} />
                      </span>
                  )}
                </Reference>
                <Popper placement="left">
                  {({ ref, style, placement, arrowProps }) => (
                    <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                      <p className="tooltip tooltip-max-width">{props.stat.tooltip}</p>
                      <div ref={arrowProps.ref} style={arrowProps.style} />
                    </div>
                  )}
                </Popper>
              </Manager>
            </span>
          }
          <FontAwesomeIcon icon={props.stat.icon} /> 
          <div className="number-container">
            <span className="name">{props.stat.name}</span>
            {props.stat.value > -1 ? 
              <span className="number">{formatNumber(props.stat.value)}</span>:
              <span className="number no-info">Inga uppgifter för tidsintervallet</span>
            }
          </div>
          {props.stat.graph_data && 
            <div className="sparkline">
            {props.stat.graph_data.length > 0 &&
              <Sparklines data={props.stat.graph_data ? (props.stat.graph_data.length < 2 ? [0,props.stat.value] : props.stat.graph_data) : [0,props.stat.value]}>
                <SparklinesLine style={{ stroke: '#ffbf99', strokeWidth:2, fill:'#ff6a13' }}/>
              </Sparklines>}
            </div>
          }
        </div>
    
    );

}

export default App;
