import {store} from '../../index';
import jsPDF from 'jspdf';
import {formatDate} from '../formatHelper';
import { createSecureContext } from 'tls';

/*
    A function used for creating a pdf from ad insights page
    TODO: Please cleanup.
*/
export async function createPDF(){
    //Retrieve info
    const info = store.getState().ad.adInfo;
    const targetInfo = store.getState().ad.target;
    const data = info.data;

    const interval = store.getState().dashboard.interval;

    const currentDate = new Date();
    let intervalDate:Date;

    if(interval === 0){
        intervalDate = new Date(store.getState().ad.adInfo.start_time);
    }else{
        intervalDate = new Date();
        intervalDate.setDate(currentDate.getDate()-interval);
    }

    const endStatDate = currentDate < new Date(info.stop_time) ? currentDate : new Date(info.stop_time);


    var doc = new jsPDF();


    getDataUri(store.getState().dashboard.background_url, function(dataUri:string) {
        // Do whatever you'd like with the Data URI!

        const marginLeft = 22;
        const marginTop = 22;
        const gap = 10;

        let currentPosition = marginTop;

        //doc.addImage(dataUri, 'PNG', marginLeft, marginTop, 60, 33);


        doc.setFontSize(28);
        doc.setFont("helvetica");
        doc.setFontStyle("bold");
        doc.text(info.name, marginLeft, currentPosition)

        currentPosition+=gap/2;
        doc.setFontSize(9);
        doc.setFontStyle("normal");
        doc.text(`ID ${info.id}`, marginLeft, currentPosition);

        currentPosition+=gap;

        doc.setFontSize(11);
        doc.setFontStyle("bold");
        doc.text(`Skapad`, marginLeft, currentPosition);
        doc.setFontSize(9);
        doc.setFontStyle("normal");
        doc.text(`${formatDate(new Date(info.created_time))}`,marginLeft, currentPosition + 5);

        doc.setFontSize(11);
        doc.setFontStyle("bold");
        doc.text(`Startdatum`, marginLeft + ((210 - (marginLeft * 2))/4), currentPosition);
        doc.setFontSize(9);
        doc.setFontStyle("normal");
        doc.text(`${formatDate(new Date(info.start_time))}`,marginLeft + ((210 - (marginLeft * 2))/4), currentPosition + 5);

        doc.setFontSize(11);
        doc.setFontStyle("bold");
        doc.text(`Slutdatum`, marginLeft + ((210 - (marginLeft * 2))/4) * 2, currentPosition);
        doc.setFontSize(9);
        doc.setFontStyle("normal");
        doc.text(`${formatDate(new Date(info.stop_time))}`,marginLeft + ((210 - (marginLeft * 2))/4) * 2, currentPosition + 5);

        doc.line(marginLeft, currentPosition + 10, 210 - marginLeft, currentPosition + 10) // horizontal line

        currentPosition += 15;

        doc.setFontSize(8);
        doc.setFontStyle("normal");
        doc.text(`*Total statistik, ${formatDate(intervalDate)} — ${formatDate(endStatDate)} `, marginLeft, currentPosition);

        //*Total statistik, 2019-10-24 — 2019-10-27

        currentPosition+=gap * 1.5;

        /*doc.setFontSize(11);
        doc.setFontStyle("bold");
        doc.text(`Statistik från ${formatDate(intervalDate)} – ${formatDate(currentDate)}`,marginLeft, currentPosition);

        currentPosition+=gap/2;

        doc.setFontSize(9);
        doc.setFontStyle("normal");
        */


        for(let i = 0; i < data.length; i++){

            //const dataString = `${data[i].name}: ${data[i].value}`;

            const rowPosition = currentPosition + 35 * (Math.floor(i/4));
            const columnMargin = marginLeft + ((210 - (marginLeft * 2))/4) * (i%4);

            doc.setFontSize(11);
            doc.setTextColor(0,0,0);
            doc.setFontStyle("bold");
            doc.text(`${data[i].name}`, columnMargin, rowPosition);

            doc.setFontSize(28);
            
            doc.setTextColor(255, 106, 19);
            doc.setFont("helvetica");
            doc.setFontStyle("bold");
            doc.text(`${data[i].value}`, columnMargin, rowPosition + 10);

            doc.setFontSize(8);
            doc.setTextColor(0,0,0);
            doc.setFontStyle("normal");

            const strArr = doc.splitTextToSize(data[i].tooltip, ((210 - (marginLeft * 2))/4) - 5)

            for(let x = 0; x < strArr.length; x++){
                doc.text(strArr[x], columnMargin, rowPosition + 15 + (x * 5));
            }

        }


        currentPosition += gap * 8;

        for(let i = 0; i < targetInfo.length; i++){
            doc.setFontSize(11);
            doc.setFontStyle("bold");
            
            const title = targetInfo[i].content.substring(0, targetInfo[i].content.length - 1)

            doc.text(title, marginLeft, currentPosition);
            for(let j = 0; j < targetInfo[i].children.length; j++){
                currentPosition+=gap/2;
                doc.setFontSize(9);
                doc.setFontStyle("normal");

                const strArr = doc.splitTextToSize(targetInfo[i].children[j], 100)

                for(let x = 0; x < strArr.length; x++){
                    doc.text(strArr[x], marginLeft, currentPosition);
                    currentPosition += gap/2;
                }

            }
            currentPosition+=gap/2;
        
        }
        
        if(dataUri){
            doc.addImage(dataUri, 'PNG', marginLeft, currentPosition, 60, 33);
        }

        currentPosition += 43;

        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica");
        doc.setFontStyle("bold");
        doc.text(`Länk till förhandsvisning`, marginLeft, currentPosition);

        currentPosition += 5;

        doc.setFontSize(14);
        doc.setTextColor(255, 106, 19);
        doc.setFont("helvetica");
        doc.setFontStyle("bold");
        doc.text(`${info.preview_shareable_link}`, marginLeft, currentPosition);


        getDataUri("/assets/tross-logo-vit.png", function(logoImg:string) {

            //Draw footer
            doc.setDrawColor(0)
            doc.setFillColor(48, 52, 58);
            doc.rect(0, 260, 210, 297, 'F');

            doc.addImage(logoImg, 'PNG', marginLeft, 297 - marginTop - Math.round(224/901) - 2, 30, 30 * Math.round(224/901));

            doc.setFontSize(8);
            doc.setTextColor(255, 255, 255);
            doc.setFontStyle("bold");
            doc.text(`© ${currentDate.getFullYear()} Tross Annonsplatform`,marginLeft, 297 - marginTop + gap);


            //Create pdf
            doc.save(info.name + '.pdf');

        });

    });
}


function getDataUri(url:string, callback:any) {
    
    if(url == "undefined"){
        callback(null);
        return;
    }

    var image:any = new Image();
    image.crossOrigin = "Anonymous";

    image.onload = function () {
        var canvas:any = document.createElement('canvas');
        canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
        canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

        canvas.getContext('2d').drawImage(this, 0, 0);

        // Get raw image data
        callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

    };

    image.src = url;
    

}