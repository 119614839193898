import React,{useState} from 'react';
import {formatDate} from '../../common/formatHelper';
import {withRouter,Link} from "react-router-dom";
import Message404 from "../Message404";
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

function AdList(props:any){

    const [loading,setLoading] = useState(false);

    const getAdSets = async () => {
        setLoading(true);
        await props.getAdSets();
        setLoading(false);
    }


    return(
        <>
            <div className="list-container-wrapper">
                <div className="list-container">
                    <div className="list-title">
                        Ad sets
                    </div>
                    <div className="list-overflow-wrapper">
                        <div className="list-content">
                            <div className="list-header">
                                <div className="list-item-wrapper">
                                    <span className="item width-fixed-150px">Id</span>
                                    <span className="item width-auto">Namn</span>
                                    <span className="item width-fixed-120px">Status</span>
                                    <span className="item width-fixed-120px">Antal annonser</span>
                                    <span className="item width-fixed-120px">Startdatum</span>
                                    <span className="item width-fixed-120px">Slutdatum</span>
                                    <span className="item width-fixed-150px"></span>
                                </div>
                            </div>
                            <ul className="list-body">
                                {props.posts.data.length > 0 &&
                                    props.posts.data.map((post:any,index:number)=>{
                                        return(
                                        <ListItem post={post} key={index} history={props.history}></ListItem>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    {props.posts.data.length === 0 && <Message404 message={"Inga ad sets hittades"} />}
                </div>
                {props.posts.next &&
                    <button className={"button" + (loading ? " loading" : "")} onClick={(e)=>{e.preventDefault(); getAdSets();}}>
                        Ladda fler
                    </button>
                }
            </div>
        </>
    );
}
  
  
function ListItem(props:any){
    const date = new Date(props.post.created_time);
    let link = `/dashboard/adset/${props.post.id}`;

    if(props.post.ads.length === 1){
        link = `/dashboard/advert/${props.post.ads[0]}`;
    }
    
    return(
        <li className="list-item">
            <div className="list-item-wrapper">
                <span className="item width-fixed-150px">{props.post.id}</span>
                <span className="item width-auto">
                <Link to={link}>
                    <button className="button-text">
                        {props.post.name}
                    </button>
                </Link>
                </span>
                <span className="item width-fixed-120px">
                    <span className={"status " + props.post.status}>
                        {props.post.status === "ACTIVE" && "Aktiv"}
                        {props.post.status === "UNCOMPLETE" && "Ej aktiv"}
                        {props.post.status === "DONE" && "Avklarad"}
                    </span>
                </span>
                <span className="item width-fixed-120px">{props.post.ads.length}</span>
                <span className="item width-fixed-120px">{formatDate(new Date(props.post.start_time))}</span>
                <span className="item width-fixed-120px">{formatDate(new Date(props.post.end_time))}</span>
                <div className="item width-fixed-150px">
                    <Link to={link}>
                        <button className="button go-to-button">
                            Gå till Ad set <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    </Link>
                </div>
            </div>
        </li>
    );
}

  
  export default withRouter(AdList);