import React from 'react';
import {store, RenderApp} from '../../index';
//import {getCampaigns} from "../../common/marketingAPI";
import App from './CampaignList';
import {getNextCampaignBatch} from "../../common/marketingAPI/campaignInfo";


async function getMoreCampaigns(){
    await getNextCampaignBatch();
    RenderApp();
    return null;
}


export default function(props:any){
    return <App posts={store.getState().campaign.campaigns} isAdmin={store.getState().login.userInfo ? store.getState().login.userInfo.admin : false} getCampaigns={getMoreCampaigns} nextBatch={store.getState().campaign.nextBatch} hideCampaign={props.hideCampaign}></App>;
}