import React from 'react';
import {store, dbAuth, RenderApp} from '../../../../index';
import App from './headerComponent';

function updateLoginState(){
    store.dispatch({type:"LOGGED_IN",isLoggedIn:false});
    store.dispatch({type:"EMAIL",email:""});
    store.dispatch({type:"USER_INFO",userInfo:{}});
    RenderApp();
    /*
        store.dispatch({type: "POST_DATA", info: []});
        store.dispatch({type: "POST_ID", id: ""});
        store.dispatch({type: "SET_OVERVIEW", info: []});
        store.dispatch({type: "SET_INTERVAL", interval: 30});

        localStorage.removeItem('email');
    */

}

export default function(){
    return <App state={store.getState()} updateLoginState={updateLoginState}></App>;
}