import React from "react";
import {Route,Switch,Redirect} from "react-router-dom";
import Dashboard from './Dashboard';
import CampaignInsights from './CampaignInsights';
import AdSet from './AdSet';
import AdInsights from './AdInsights';
import PrivacyPolicy from './privacyComponent';
import Header from "./headerComponent";
import Footer from "./PrivateFooter/";

function PrivateDashboard(props:any){
    return(
        <>
            <Header></Header>
            <Switch>
                <Route path="/dashboard/campaign/:id" component={CampaignInsights}/>
                <Route path="/dashboard/adset/:id" component={AdSet}/>
                <Route path="/dashboard/advert/:id" component={AdInsights}/>
                <Route path="/dashboard/privacy/" component={PrivacyPolicy}/>
                <Route exact path="/dashboard/" component={Dashboard}/>
                <Route render={() => <Redirect to="/dashboard/" />} />
            </Switch>
            <Footer></Footer>
        </>
    );
}


export default PrivateDashboard;