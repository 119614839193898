import React,{useRef, useEffect, useState} from 'react';
import {formatNumber,formatDate} from '../../common/formatHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faArrowLeft, faArrowRight, faPencilAlt, faTimes, faCheck, faEyeSlash, faEye, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {withRouter,Link} from "react-router-dom";
import Message404 from "../Message404";
import { Manager, Reference, Popper } from 'react-popper';
import './style.scss';

function ActiveCards(props:any){
    const draggableRef:any = useRef();

    let isDown = false;
    let startX:any;
    let scrollLeft:any;


    const mouseDown = (e:any) => {
      isDown = true;
      startX = e.pageX - draggableRef.current.offsetLeft;
      scrollLeft = draggableRef.current.scrollLeft;
    }

    const mouseLeave = () => {
      isDown = false;
    }

    const mouseUp = () => {
      isDown = false;
    }

    const mouseMove = (e:any) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - draggableRef.current.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      draggableRef.current.scrollLeft = scrollLeft - walk;
    }


  const goRight = () => {

    if(!draggableRef.current){
      return;
    }

    scrollLeft = draggableRef.current.scrollLeft + window.innerWidth;
    draggableRef.current.scrollLeft = scrollLeft;
  }

  const goLeft = () => {

    if(!draggableRef.current){
      return;
    }

    scrollLeft = draggableRef.current.scrollLeft - window.innerWidth;
    draggableRef.current.scrollLeft = scrollLeft;
  }

  return(
    <div className="card-container">
      <div className="wrapper-1280 flex-row">
        <div className="title-container">
          <h2>Senaste kampanjer</h2>
          <p>Här finner du dina aktiva kampanjer samt nyligen avslutade kampanjer.</p>
        </div>
        <div className="navigation">
          <button className="arrow-button" onClick={(e)=>{e.preventDefault(); goLeft();}}>
            <FontAwesomeIcon icon={faArrowLeft}/>
          </button>
          <button className="arrow-button" onClick={(e)=>{e.preventDefault(); goRight();}}>
            <FontAwesomeIcon icon={faArrowRight}/>
          </button>
        </div>
      </div>

      {props.activeCampaigns.length > 0 ?  
        <div className="scrollable-container"> 
            <ul className="active-container" ref={draggableRef}
              onMouseDown={(e) => {mouseDown(e)}} 
              onMouseMove={(e)=>{mouseMove(e)}} 
              onMouseUp={()=>{mouseUp()}}
              onMouseLeave={()=>{mouseLeave()}}>
                {props.activeCampaigns.map((campaign:any,index:number)=>{
                  return(
                    <React.Fragment key={index}>
                    {(!campaign.hidden || props.isAdmin) &&
                      <CampaignCard info={campaign} history={props.history} saveName={props.saveName} isAdmin={props.isAdmin} hideCampaign={props.hideCampaign}/>
                    }
                    </React.Fragment>
                  )
                })}
            </ul>
            <Message404 message={"Inga kampanjer matchade filtret"}></Message404>
        </div>
      : (props.loading ? <Message404 message={"Laddar..."}></Message404> : <Message404 message={"Inga kampanjer hittades"}></Message404> )}
    </div>
  );
}


function CampaignCard(props:any){
  const imageRef:any = useRef();
  const [edit,setEdit] = useState(false);
  const [title,setTitle] = useState(props.info.custom_name ? props.info.custom_name : props.info.name);

  useEffect(()=>{
      var newImg = new Image;
      newImg.onload = function() {
        imageRef.current.classList.add("reveal");
      }
      newImg.src = props.info.thumbnail_url;
  
    },[props.info.thumbnail_url]);

  let link = `/dashboard/campaign/${props.info.id}`;

  if(props.info.adsets.length === 1){
    link = `/dashboard/adset/${props.info.adsets[0]}`;
    if(props.info.ads.length === 1){
      link = `/dashboard/advert/${props.info.ads[0]}`;
    }
  }

  const cancelEdit = () => {
    setEdit(false);
    setTitle(props.info.custom_name ? props.info.custom_name : props.info.name);
  }

  const saveName = async () => {
    setEdit(false);
    
    //Only make a write to the database if the name is different
    if(props.info.custom_name === title){
      return;
    }

    if(await props.saveName(props.info.id,title)){
      //Save successfull
    }else{
      //Couldn't save
    }
  }


  return(
    <li className="filter_element">
      <div className="active-card">
        {props.isAdmin &&
          <>
          <HideAdButton id={props.info.id} hideCampaign={props.hideCampaign} hidden={props.info.hidden}></HideAdButton>
          </>
        }

        <div className={"status " + props.info.status}>
          {props.info.status === "ACTIVE" && "aktiv"}
          {props.info.status === "UNCOMPLETE" && "ej aktiv"}
          {props.info.status === "DONE" && "avklarad"}
        </div>  
        <Link to={link}>
          <div className="image-wrapper">
            <div className="image" style={{backgroundImage:'url(' + props.info.thumbnail_url + ')', backgroundPosition: 'center', backgroundSize: 'cover'}} ref={imageRef}></div>
            {props.info.thumbnail_url === "undefined" && <div className="image404">Ingen bild hittades</div>}
            <div className="user-info tooltip-container">
              <Manager>
                <Reference>
                  {({ ref }) => (
                      <span ref={ref}>
                        <FontAwesomeIcon icon={faUserCircle}/>
                      </span>
                  )}
                </Reference>
                <Popper placement="left">
                  {({ ref, style, placement, arrowProps }) => (
                    <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                      <div className="tooltip">
                        <span className="text"><span className="filter_variable">{props.info.ordered_by}</span></span>
                      </div>
                      <div ref={arrowProps.ref} style={arrowProps.style} />
                    </div>
                  )}
                </Popper>
              </Manager>
            </div>
          </div>
        </Link>
        <div className="active-info">
          <div className="general-info">
            <div className="title-container">
              <span className="title-main-wrapper">
                {edit ? 
                  <>
                    <InputField value={title} defaultValue={props.info.name} setValue={setTitle}/>
                    {/*<input ref={inputRef} className="title-input" type="text" value={title} placeholder={props.info.name} onChange={(e)=>{setTitle(e.currentTarget.value)}}/>*/}
                    <button className="no-bg title-edit tooltip-container" onClick={()=>{cancelEdit()}}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                    <button className="no-bg title-edit" onClick={()=>{saveName()}}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </button>
                  </>
                  : 
                  <>
                    <Link to={link}>
                      <button className="button-text title">
                        <span className="filter_variable">{props.info.custom_name ? props.info.custom_name : props.info.name}</span>
                      </button>
                    </Link>
                    <button className="no-bg title-edit" onClick={()=>{setEdit(true);}}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </button>
                  </>
                }
                
              </span>
              <span className="info"><span className="filter_variable">{props.info.id}</span></span>
            </div>

            <div className="date tooltip-container">
              <Manager>
                <Reference>
                  {({ ref }) => (
                      <span ref={ref}>
                        <FontAwesomeIcon icon={faCalendarAlt}/>
                      </span>
                  )}
                </Reference>
                <Popper placement="left">
                  {({ ref, style, placement, arrowProps }) => (
                    <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                      <div className="tooltip">
                        <span className="text">{"Startdatum " + formatDate(new Date(props.info.start_time))}</span>
                        <span className="text">{"Slutdatum " + formatDate(new Date(props.info.stop_time))}</span>
                      </div>
                      <div ref={arrowProps.ref} style={arrowProps.style} />
                    </div>
                  )}
                </Popper>
              </Manager>
            </div>
          </div>

          <div className="stats">
            <span className="disclamer">Statistik senaste månaden</span>
            <div className="number-container">
              <span className="name">Visningar</span>
              <span className="number">{formatNumber(props.info.impressions)}</span>
            </div>
            <div className="number-container">
              <span className="name">Klick</span>
              <span className="number">{formatNumber(props.info.clicks)}</span>
            </div>
            <div className="number-container">
              <span className="name">CTR</span>
              <span className="number">{props.info.ctr > 0 ? Math.round(props.info.ctr*100)/100 : 0}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

function InputField(props:any){
  const inputRef:any = useRef();
  
  useEffect(()=>{
    inputRef.current.focus();
  },[]);

  return(
    <input ref={inputRef} className="title-input" type="text" value={props.value} placeholder={props.defaultValue} onChange={(e)=>{props.setValue(e.currentTarget.value)}}/>
  );
}


function HideAdButton(props:any){
  const [hidden,setHidden] = useState((props.hidden ? props.hidden : false));

  const revealAd = () => {
    setHidden(false);
    props.hideCampaign(props.id,false);
  }

  const hideAd = () => {
    setHidden(true);
    props.hideCampaign(props.id,true);
  }

  return(
      <span className="tooltip-container">
        <Manager>
          <Reference>
            {({ ref }) => (
                <span ref={ref}>
                  {hidden ? 
                    <button className="no-bg hide-button reveal" onClick={(e)=>{e.preventDefault(); revealAd()}}>
                      <FontAwesomeIcon icon={faEye}/>
                    </button> :
                    <button className="no-bg hide-button hide" onClick={(e)=>{e.preventDefault(); hideAd();}}>
                      <FontAwesomeIcon icon={faEyeSlash}/>
                    </button>
                  }
                </span>
            )}
          </Reference>
          <Popper placement="right">
            {({ ref, style, placement, arrowProps }) => (
              <div ref={ref} style={style} data-placement={placement} className="popper-wrapper">
                <div className="tooltip">
                  <span className="text">{hidden ? "Visa kampanj" : "Göm kampanj"}</span>
                </div>
                <div ref={arrowProps.ref} style={arrowProps.style} />
              </div>
            )}
          </Popper>
      </Manager>
    </span>
  );

}


export default withRouter(ActiveCards);