import React,{useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import {dbAuth} from '../../../../index';
import { withRouter, Link} from "react-router-dom";
import "./style.scss";

function App(props:any){
  const headerRef:any = useRef();
  const user = props.state.login.email;

  const signOut = () => {

    dbAuth.signOut().then(function() {
        // Sign-out successful.
        props.updateLoginState();

        props.history.push("/admin/login");

      }).catch(function(error:any) {
        // An error happened.
        console.log("Kunde inte logga ut")
    });

  }


  return(
    <>
      <header className="ghost-element">
        <div className="wrapper">
            <button className="logo header-button">
              <img src="/assets/logo.png" alt="logo"></img>
            </button>
            <nav>
                <ul>
                    <li>
                        <button className="header-button">
                          <span>{user}</span>
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                        <span className="header-pulldown">
                          <ul>
                              <li>
                                <button className="list-button">
                                  <FontAwesomeIcon icon={faSignOutAlt} /> Logga ut
                                </button>
                              </li>
                          </ul>   
                        </span> 
                    </li>
                </ul>
            </nav>
        </div>
      </header>
      <header ref={headerRef}>
        <div className="wrapper">
            <Link to="/admin/dashboard/">
              <button className="logo header-button">
                <img className="reveal" src="/assets/logo.png"></img>
              </button>
            </Link>
            <nav>
                <ul>
                    <li>
                        <button className="header-button">
                          <span className="text">{user}</span>
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                        <span className="header-pulldown">
                          <ul>
                              <li>
                                <button className="list-button" onClick={(e) => {e.preventDefault(); signOut();}}>
                                  <FontAwesomeIcon icon={faSignOutAlt} /> Logga ut
                                </button>
                              </li>
                          </ul>
                        </span>    
                    </li>
                </ul>
            </nav>
        </div>
      </header>
    </>

  );

}

export default withRouter(App);
