export function formatDate(d:Date):String{
    return `${d.getFullYear()}-${(d.getMonth()+1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1)}-${d.getDate()<10?"0"+(d.getDate()):(d.getDate())}`
}

export function formatNumber(value:number):string{

    let formattedNbr:string;

    if(value > 1000){
      formattedNbr = ""+Math.round((value/1000))+"k";
    }else{
      formattedNbr = ""+value;
    }

    return formattedNbr;
}
