import React,{useRef,useState,useEffect} from 'react';
import {dbAuth,dbRef, db} from '../../../index';
import { withRouter, Link} from "react-router-dom";
import "../style.scss";

function App(props:any){

    const loginRef:any = useRef();
    const emailErrorRef:any = useRef();
    const pwErrorRef:any = useRef();
    const confirmPwRef:any = useRef();
    const infoMessage:any = useRef();


    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPw, setConfirmPw] = useState("");

    const [emailError,setEmailError] = useState(false);
    const [pwError,setPwError] = useState(false);
    const [confirmPwError,setConfirmPwError] = useState(false)

    const createAccount = () => {

        let error = false;
    
        if(!email){
            error = true;
            emailErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setEmailError(true);
        }

        if(!password){
            error = true;
            pwErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setPwError(true);
        }

        if(!confirmPw){
            error = true;
            setConfirmPwError(true);
            confirmPwRef.current.innerHTML = "Fältet får inte vara tomt";
        }else if(confirmPw !== password){
            error = true;
            confirmPwRef.current.innerHTML = "Lösenorden matchar ej";
            setConfirmPwError(true);
        }

        if(error){
            return;
        }


        loginRef.current.classList.add("loading");
    
        dbAuth.createUserWithEmailAndPassword(email, password).then(async function(){

            loginRef.current.classList.remove("loading");
            infoMessage.current.classList.add("success");

            if(dbAuth.currentUser){

                dbAuth.currentUser.sendEmailVerification().then(()=>{
                    // Email sent.
                }).catch((error:any)=>{
                    // An error happened.
                });

                /*const domain = email.split("@")[1].replace(".",",");
                const sanitizedEmail = email.split(".").join(",");
        
                const creationDate = dbAuth.currentUser.metadata.creationTime;
                console.log(dbAuth.currentUser.uid)
                console.log(dbAuth.currentUser)
                const userId = dbAuth.currentUser.uid;
                console.log(dbAuth.currentUser)
                console.log(dbAuth.currentUser.uid)
                await dbRef.child('Users').child(domain).child(sanitizedEmail).set({
                    creationDate: creationDate,
                    email: email,
                    userId: userId,
                    disabled: false,
                    emailVerified:true,
                }).then(()=>{
                    return null;
                });

                await db.collection("users").doc(email).set({
                    email: `${email}`,
                    created_date: `${new Date()}`,
                },{merge:true}).then(()=>{
                    console.log("added user")
                    return null;
                });*/


                await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/ad_user`,{
                    method: 'POST', // or 'PUT'
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({email:email}),
                  }).then((response:any)=>{
                    console.log("user added successfully")
                    return null;
                }).catch(()=>{
                    console.log("woopsie")
                    return null;
                });


                await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/check_if_disabled?email=${email}`).then((response:any)=>{
                    return null;
                }).catch(()=>{
                    return null;
                });

            }

        }).catch(function(error:any) {
            console.log(error)
            loginRef.current.classList.remove("loading");
    
            const errorCode = error.code;

            if(errorCode === 'auth/invalid-email'){
                setEmailError(true);
                emailErrorRef.current.innerHTML = "Ogiltig mailaddress";
            }else if(errorCode === 'auth/email-already-in-use'){
                setEmailError(true);
                emailErrorRef.current.innerHTML = "Mailaddressen används redan";
            } else if(errorCode === 'auth/weak-password'){
                setPwError(true);
                pwErrorRef.current.innerHTML = "Lösenordet måste vara minst 6 tecken";
            }else if(error.code === "auth/network-request-failed"){
                setConfirmPwError(true);
                confirmPwRef.current.innerHTML = "Kunde inte koppla nå nätverket.";
            }else{
                setConfirmPwError(true);
                confirmPwRef.current.innerHTML = "Något gick fel";
            }

        });

    }


    useEffect(()=>{
        setEmailError(false);
    },[email]);

    useEffect(()=>{
        setPwError(false);
    },[password]);

    useEffect(()=>{
        setConfirmPwError(false);
    },[confirmPw]);


    return(
        <>
            <div className="login-container">
                <h1>Skapa konto</h1>
                <form className="form" onSubmit={(e) => {e.preventDefault(); createAccount()}}>
                    <div className={"input-wrapper" + (emailError ? " error" :"")}>
                        <input placeholder="Email" type="text" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message error-message">
                            <p ref={emailErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <div className={"input-wrapper" + (pwError ? " error" :"")}>
                        <input placeholder="Lösenord" type="password" value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message error-message">
                            <p ref={pwErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <div className={"input-wrapper" + (confirmPwError ? " error" :"")}>
                        <input placeholder="Bekräfta lösenord" type="password" value={confirmPw} onChange={(e) => {setConfirmPw(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message info-message" ref={infoMessage}>
                            <p>Kontot har skapats! Verifiera din mailaddress för att kunna logga in.</p>
                        </div>
                        <div className="message error-message">
                            <p ref={confirmPwRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <button className="button" type="submit" ref={loginRef}>
                        Skapa konto
                    </button>
                </form>
                <div className="login-footer">
                    <p>Har du redan ett konto? <Link to="/login"><button className="button-text">Logga in</button></Link></p>
                </div>
            </div>
        </>
    );

}


export default withRouter(App);
