import React from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router} from "react-router-dom";
import './App.scss';
import PrivateDashboard from './components/PrivateDashboard';
import PublicComponent from './components/PublicComponent';
import AdminComponent from './components/AdminComponent';

function App(props:any){

  return(
    <Router>
      <Switch>
        <Route path="/admin/" component={AdminComponent}/>
        <PrivateRoute path="/dashboard/" location={props.location} component={PrivateDashboard} isLoggedIn={props.state.login.isLoggedIn}/>
        <Route path="/" component={PublicComponent}/>
      </Switch>
    </Router>
  );

}

function PrivateRoute({component: Component, isLoggedIn, location, ...rest}:any){
  return (

      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route {...rest} render={props => (
          isLoggedIn ?
              <Component {...props} />
          : <Redirect to={{
            pathname: '/login',
            state: { previousLocation: location.pathname }
        }}/>
      )} />
  );
};


export default App;
