import React,{useState, useEffect} from 'react';
import { RenderApp } from '../..';
import "./style.scss";

function DateInterval(props:any){

  const setInterval = (interval:number)=>{
    props.setInterval(interval);

    const statCards = document.querySelectorAll('.stat-card');

    for(let i = 0; i < statCards.length; i++){
        statCards[i].classList.add('loading');
    }

    props.fetchStats(props.id).then(function(){
      for(let i = 0; i < statCards.length; i ++){
          statCards[i].classList.remove('loading');
      }    

      RenderApp();
    });

  }

  return(

    <>
      <div className="dateInterval">
        <DateButton interval={7} setInterval={setInterval} text="7 d." selectedInterval={props.state.dashboard.interval}/>
        <DateButton interval={28} setInterval={setInterval} text="28 d." selectedInterval={props.state.dashboard.interval}/>
        <DateButton interval={0} setInterval={setInterval} text="Totalt" selectedInterval={props.state.dashboard.interval}/>
      </div>
    </>

  );
}

function DateButton(props:any){
  const [selected,setSelected] = useState(false);

  useEffect(()=>{
    if(props.selectedInterval !== props.interval){
      setSelected(false);
    }else{
      setSelected(true);
    }
  },[props.selectedInterval])

  return(
    <button onClick={(e) => {e.preventDefault(); props.setInterval(props.interval); setSelected(true)}} className={selected ? "selected" : ""}>{props.text}</button>
  );
}


export default DateInterval;
