import React from 'react';
import {store, RenderApp, db, storage} from '../../index';
import App from './loginComponent';


async function updateLoginState(email:string){
    const userInfo = await getUserInfo(email);
    store.dispatch({type:"USER_INFO",userInfo:userInfo});
    store.dispatch({type:"LOGGED_IN",isLoggedIn:true});
    store.dispatch({type:"EMAIL",email:email});

    RenderApp();
}

async function getUserInfo(email:string){

    const response = await db.collection('users').doc(email).get().then((querySnapshot:any)=>{

        if(!querySnapshot.exists){
            return null;
        }

        return querySnapshot.data();
    
    });

    if(response){

        const {company_id} = response;
        let logo_url = null;

        if(company_id){
            logo_url = await storage.ref("logos/" + company_id + ".png").getDownloadURL().then(function(url:any) {
                return url;
            }).catch((error:any)=>{
                return null;
            });
        }

        response.logo_url = logo_url;

        return response;
    }

    return null;

}


export default function(props:any){
    return <App state={store.getState()} location={props.state ? props.state.previousLocation : ""} updateLoginState={updateLoginState}></App>;
}