import React, { useEffect, useState } from 'react';
import ActiveCards from '../ActiveCards';
import ActiveCardsScroll from '../ActiveCardsScroll';
import CampaignList from '../CampaignList';
import ScrollToTop from "../ScrollTop";
import Search from "../Search";

function AccountComponent(props:any){

  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    const fetchStats = async () => {
      await props.fetchStats();
      setLoading(false);
    }

    fetchStats();

  },[])

  return(
    <>
      <ScrollToTop></ScrollToTop>
        <Search></Search>
        <main>
          
          {/*<Hero info={props.state.dashboard.info}  fetchStats={props.fetchAccountInsights}/>*/}
          {/*<ActiveCards activeCampaigns={props.state.campaign.activeCampaigns} loading={loading}/> */}
          <ActiveCardsScroll activeCampaigns={props.state.campaign.activeCampaigns} loading={loading} hideCampaign={props.hideCampaign}/>
          <CampaignList hideCampaign={props.hideCampaign}/>
        </main>
    </>
  );
}




export default AccountComponent;
