import React, { useEffect, useState, useRef } from "react";
import {Link,withRouter} from "react-router-dom"
import "./style.scss";
import{formatDate} from "../../../../common/formatHelper";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faThumbsDown, faThumbsUp, faUserShield, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

function Dashboard(props:any){

    const [users,setUsers] = useState<any[]>([]);
    const [filteredUsers,setFilteredUsers] = useState<any[]>([]);
    const [filters,setFilters] = useState<String[]>([]);
    const [companies,setCompanies] = useState([]);    

    const [message,setMessage] = useState({
        text:"",
        success:true,
        reveal:false,
    });

    const getUsers = async () => {
        const response = await props.getUsers();
        if(response){
            setUsers(response);
            setFilteredUsers(response);
        }
    }

    const getCompanies = async () => {
        const response = await props.getCompanies();
        if(response){
            setCompanies(response);
        }
    }

    useEffect(()=>{    

        getUsers();
        getCompanies();
    
    },[]);


    const filterList = (addingFilter:boolean,filter:string) => {

        if(addingFilter){//Ad filter to array 
            setFilters([...new Set([...filters,filter])]);
        }else{//else remove filter from array
            setFilters(filters => {
                
                const arr = [...filters];

                for(let i = 0; i < arr.length; i++){
                    if(arr[i] === filter){
                        arr.splice(i,1);
                    }
                }
                return [...arr];
            });
        }
    }

    useEffect(()=>{

        const filterList = () => {

            let userList = [...users];//Get the original array
            let filteredList:any[] = [];

            if(filters.length === 0){//Check if any filters
                setFilteredUsers([...userList]);
                return;
            }

            for(let i = 0; i < filters.length; i++){

                if(filters[i] === "admin"){//Filter admins
                    
                    const listTmp = userList.filter((user)=>{
                        if(user.admin){
                            return true;
                        }else{
                            return false;
                        }
                    });

                    filteredList = [...new Set([...filteredList,...listTmp])];

                }

                if(filters[i] === "uid"){//Filter active users
                    const listTmp = userList.filter((user)=>{
                        if(user.uid){
                            
                            return true;
                        }
                        return false;
                    });

                    filteredList = [...new Set([...filteredList,...listTmp])];
                }

                if(filters[i] === "incative_account"){//Filter inactive accounts
                    const listTmp = userList.filter((user)=>{
                        if(user.uid){
                            return false;
                        }else{
                            return true;
                        }
                    });

                    filteredList = [...new Set([...filteredList,...listTmp])];
                }

            }


            setFilteredUsers([...filteredList]);

        }

        filterList();

    },[filters]);


    return(
        <>
            <div className="main__wrapper">
                <div className="breadcrumbs-wrapper">
                    <p className="breadCrumbs">
                        <span><Link to="/admin/dashboard/">Överblick</Link></span>
                        <span><Link to={"/admin/users/"}>Alla användare</Link></span>
                    </p>
                </div>
                {message.reveal && 
                    <InfoMessage success={message.success} message={message.text} setMessage={setMessage}></InfoMessage>
                }
                <div className="userlist_title">
                    <h2>Hantera användare</h2>
                    <div className="filter-container">
                        <Checkbox label="Oaktiverat konto" filterKey="incative_account" onChange={filterList}></Checkbox>
                        <Checkbox label="Aktiverat konto" filterKey="uid" onChange={filterList}></Checkbox>
                        <Checkbox label="Admins" filterKey="admin" onChange={filterList}></Checkbox>
                    </div>
                </div>
                <UserList users={filteredUsers} setBlacklistStatus={props.setBlacklistStatus} setMessage={setMessage} companies={companies} setUserOrganisation={props.setUserOrganisation}></UserList>
            </div>
        </>
    );
}

function InfoMessage(props:any){

    const infoRef:any = useRef();

    useEffect(()=>{
        

        setTimeout(()=>{

            if(!infoRef.current){
                return;
            }

            infoRef.current.classList.add("remove");

            setTimeout(()=>{
                props.setMessage((message:any)=>{
                    const newMessage = {
                        text:message.text,
                        success:message.success,
                        reveal:false,
                    }
    
                    return newMessage;
                });
            },200)

        },2000)

    },[]);

    return(
        <div className="popup-message" ref={infoRef}>
            <span className={"symbol" + (props.success ? " success" : " fail")}>
                {props.success ? 
                    <FontAwesomeIcon icon={faCheckCircle} />:
                    <FontAwesomeIcon icon={faExclamationCircle} /> 
                } 
            </span>
            <span className="message">              
                {props.message}
            </span>     
        </div>
    );
}

function UserList(props:any){

    const [users,setUsers] = useState([...props.users]);
    const [sortState, setSortState] = useState("");

    useEffect(()=>{
        setUsers([...props.users]);
    },[props.users]);


    return (
        <>
            {props.users.length > 0 && 
                <>
                    <div className="userlist">
                        <ul className="userlist_list-body userlist_list-header">
                            <li className="list-item list-item-header">
                                <div className="list-item-wrapper">
                                    <div className="list-item-content">
                                        <span className="item width-250px">
                                            <SortButton setList={setUsers} sortState={sortState} setSortState={setSortState} list={users} title="Email" resetList={props.users} sort_key="email"></SortButton>
                                        </span>
                                        <span className="item">
                                            <button className="sort-button sort-inactive">
                                                Aktiverat konto
                                            </button>
                                        </span>
                                        <span className="item">
                                            <SortButton setList={setUsers} sortState={sortState} setSortState={setSortState} list={users} title="Senast inloggad" resetList={props.users} sort_key="lastSignInTime" type={"date"}></SortButton>  
                                        </span>
                                        <span className="item">
                                            <SortButton setList={setUsers} sortState={sortState} setSortState={setSortState} list={users} title="Organisation" resetList={props.users} sort_key="company_id"></SortButton>
                                        </span>
                                        <span className="item">
                                            <button className="sort-button sort-inactive">
                                                Kontot skapat
                                            </button>
                                        </span>
                                    </div>
                                    <div className="list-actions">
                                        <button className="button blacklist hidden">
                                            <FontAwesomeIcon icon={faThumbsDown} /> 
                                            Svartlista
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="userlist_list-body">
                            {users.map((user:any,index:number)=>{
                                return(
                                    <UserListItem key={user.uid ? user.uid : user.id} user={user} setBlacklistStatus={props.setBlacklistStatus} setMessage={props.setMessage} companies={props.companies} setUserOrganisation={props.setUserOrganisation}/>
                                )
                            })}
                        </ul>
                    </div>                                
                </>
            }
        </>
    );
}


function UserListItem(props:any){

    const [blacklistStatus,setBlacklistStatus] = useState(props.user.blacklisted ? props.user.blacklisted : props.user.disabled);
    const [company,setCompany] = useState((props.user.company_id ? props.user.company_id : ""));


    const updateBlacklistStatus = () => {
        let blacklisted = blacklistStatus;
        if(blacklisted){
            blacklisted = !blacklisted;
        }else{//if blacklist status is null
            blacklisted = true;
        }

        setBlacklistStatus(blacklisted);

        if(props.setBlacklistStatus(props.user.email,blacklisted)){
            //TODO update message
            const newMessage = {
                text:(blacklisted ? "Användare svartlistad!" : "Borttagen från svartlistan!"),
                success:true,
                reveal:true,
            }
            props.setMessage(newMessage);
        }
    }

    const selectOrganisation = (company_id:string) => {
        props.setUserOrganisation(props.user.email,company_id);
        setCompany(company_id);

        const newMessage = {
            text:"Organisation uppdaterad!",
            success:true,
            reveal:true,
        }
        props.setMessage(newMessage);
    }


    return(
        <li className="list-item">
            <div className="list-item-wrapper">
                <div className="list-item-content">
                    <span className="item width-250px">
                        <label>Email</label>
                        <span className="list-item-value">
                            {props.user.admin && <span className="admin-tag">
                                <FontAwesomeIcon icon={faUserShield} /> 
                            </span>}
                            {props.user.email}
                        </span>
                    </span>
                    <span className="item">
                        <label>Aktiverat konto</label>
                        <span className="list-item-value">{props.user.uid ? "Ja" : "Nej"}</span>
                    </span>
                    <span className="item">
                        <label>Senast inloggad</label>
                        <span className="list-item-value">{props.user.lastSignInTime ? formatDate(new Date(props.user.lastSignInTime)) : "Aldrig"}</span>
                    </span>
                    <span className="item">
                        <label>Organisation</label>
                            <span className="list-item-value">
                                <select className="select-menu" value={company} onChange={(e)=>{e.preventDefault(); selectOrganisation(e.currentTarget.value);}}>
                                    <option value="">Ingen organisation</option>
                                    {props.companies && props.companies.map((val:any,key:number)=>{
                                        return( 
                                            <option key={key} value={val.id}>{val.name ? val.name : val.id}</option>
                                        );
                                    })}
                                </select>
                            </span>
                        
                    </span>
                    <span className="item">
                        <label>Kontot skapat</label>
                        <span className="list-item-value">{props.user.creationTime ? formatDate(new Date(props.user.creationTime)) : formatDate(new Date(props.user.created_date))}</span>
                    </span>
                </div>
                <div className="list-actions">
                    {!blacklistStatus ? 
                        <button className="button blacklist" onClick={updateBlacklistStatus}>
                            <FontAwesomeIcon icon={faThumbsDown} /> 
                            Svartlista
                        </button>:
                        <button className="button whitelist" onClick={updateBlacklistStatus}>
                            <FontAwesomeIcon icon={faThumbsUp} /> 
                            Vitlista
                        </button>
                    }
                </div>
            </div>
        </li>
    );
}

function Checkbox(props:any){

    const [checked,setChecked] = useState(false)

    const updateCheckbox = (value:boolean) => {
        setChecked(value);
        props.onChange(value, props.filterKey);
    }

    return(
        <label className={"checkbox" + (checked ? " checked" : "")}>
            <input type="checkbox" checked={checked} onChange={(e)=>{updateCheckbox(e.currentTarget.checked)}}/>
            <span>{props.label}</span>
        </label>        
    );
};

function SortButton(props:any){
    const [ascSort,setAscSort] = useState(true);
  
    const sortASC = () => {
      props.list.sort((a:any, b:any) => {
  
        const key = props.sort_key;

        if(props.type === "date"){
            if(new Date(a[key]) < new Date(b[key])){
                return -1;
            }
            if(new Date(a[key]) > new Date(b[key])){
                return 1;
            }
            return 0;
        }


        if(!a[key] || !b[key])
          return 0;

        const valueA = a[key].toLowerCase();
        const valueB = b[key].toLowerCase();
  
        if (valueA < valueB) //sort string ascending
            return -1 
        if (valueA > valueB)
            return 1
        return 0 //default return value (no sorting)
      });
      props.setList([...props.list]);
    }
  
    const sortDESC = () => {
      props.list.sort((a:any, b:any) => {
  
        const key = props.sort_key;
  
        if(props.type === "date"){
            if(new Date(a[key]) > new Date(b[key])){
                return -1;
            }
            if(new Date(a[key]) < new Date(b[key])){
                return 1;
            }
            return 0;
        }

        if(!a[key] || !b[key])
          return 0;

        const valueA = a[key].toLowerCase();
        const valueB = b[key].toLowerCase();
  
        if (valueA > valueB) //sort string ascending
            return -1 
        if (valueA < valueB)
            return 1
        return 0 //default return value (no sorting)
      });
      props.setList([...props.list]);
    }
  
  
    const sort = () => {

      if(props.sortState !== props.title || (props.sortState && !ascSort)){
        props.setSortState(props.title);//Change which sort is selected
        if(ascSort){
          sortASC();
          setAscSort(false);
        }else{
          sortDESC();
          setAscSort(true);
        }
        setAscSort(!ascSort);
  
      }else{
        props.setSortState("");
        resetList();
      }
    }
  
    const resetList = () => {
        props.setList([...props.resetList]);
    }

    return(
      <button className={props.sortState === props.title ? "sort-button selected" : "sort-button"} onClick={(e) => { sort(); }}>
        {props.title}
        <span className={ascSort ? "arrow desc" : "arrow asc"}>
            <FontAwesomeIcon icon={faArrowDown} />
        </span>
      </button>
    );
}



export default withRouter(Dashboard);