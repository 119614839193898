import React from "react";
import "./style.scss";
import {store, RenderApp, db} from '../../../../index';
import App from './Dashboard';


async function getUsers(){

    const users:any = [];

    await db.collection("users").get().then((querySnapshot:any)=>{
        querySnapshot.docs.forEach((doc:any) => {
            users.push(doc.data());
        });
        return null;
    });

    return users;

}

async function getCompanies(){
    const companies:any = [];

    await db.collection("companies").get().then((querySnapshot:any)=>{
        querySnapshot.docs.forEach((doc:any) => {
            companies.push(doc.data());
        });
        return null;
    });

    return companies;
}


async function addAdmin(email:string,name:string){
    // Add a new document in collection "cities"
    const response = await db.collection("admins").doc(email).set({
        name: name,
        email: email,
    },{ merge: true }).then(async function() {

        const userResponse = await db.collection("users").doc(email).set({
            name: name,
            email: email,
            admin: true,
        },{ merge: true }).then(function() {
            return true;
        }).catch(function(error:any) {
            return false;
        });

        return userResponse;
    }).catch(function(error:any) {
        return false;
    });


    return response;
}

async function addOrganisation(id:string,name:string){
    
    const response = await db.collection("companies").doc(id.toUpperCase()).set({
        id: id.toUpperCase().trim(),
        name: name.trim(),
    },{ merge: true }).then(function() {
        return true;
    }).catch(function(error:any) {
        return false;
    });

    return response;
}

async function getAdminInfo(email:string){
    const response = await db.collection("admins").doc(email).get().then(function(docSnapshot:any) {
        if(docSnapshot.exists){
            return docSnapshot.data();
        }
        return null;
    }).catch(function(error:any) {
        return null;
    });

    return response;
}

export default function(){
    return <App state={store.getState()} getUsers={getUsers} getCompanies={getCompanies} addAdmin={addAdmin} addOrganisation={addOrganisation} getAdminInfo={getAdminInfo}></App>;
}