const defaultState = {
    campaigns:{
        data:[],
        next:"",
    },
    campaignInfo:null,
    activeCampaigns:[],
    nextBatch:null,
};

export default function(state = defaultState, action:any){

    if(action.type === "SET_CAMPAIGNS"){
        return { ...state, campaigns: action.campaigns };
    }

    if(action.type === "SET_ACTIVE_CAMPAIGNS"){
        return { ...state, activeCampaigns: action.activeCampaigns }
    }

    if(action.type === "CAMPAIGN_INFO"){
        return { ...state, campaignInfo: action.campaignInfo };
    }

    if(action.type === "NEXT_BATCH"){
        return { ...state, nextBatch: action.nextBatch };
    }

    return state;
};