import React from "react";
import "./style.scss";
import {store, db, storage} from '../../../../index';
import App from './AllUsers';


async function getUsers(){

    const users:any = await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/get_all_users`).then((response)=>{
        return response.json();
    }).then((json:any)=>{
        return json;
    }).catch((error:any)=>{
        return null;
    });

    return users;

}

async function getCompanies(){

    const response = db.collection("companies").get().then((querySnapshot:any) => {

        const companies:any = [];

        querySnapshot.forEach((doc:any) => {

            companies.push(doc.data());

        });

        return companies;

    }).catch(function(error:any) {
            return false;
    });


    return response;

}


async function setBlacklistStatus(email:string,blacklist:boolean){

    await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/disable_user?email=${email}`).then((response)=>{
        return response.json();
    }).then((json:any)=>{
        return json;
    }).catch((error:any)=>{
        return null;
    });


    const response = await db.collection("users").doc(email).set({
        email:email,
        blacklisted: blacklist,
    },{ merge: true }).then(function() {
        console.log("Document successfully written!");
        return true;
    }).catch(function(error:any) {
        console.error("Error writing document: ", error);
        return false;
    });

    return response;
}

async function setUserOrganisation(email:string,company_id:string){
    const response = await db.collection("users").doc(email).set({
        company_id:company_id,
    },{ merge: true }).then(function() {

        if(company_id){
            fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/send_email/added_to_org?email=${email}&company_id=${company_id}`).then(()=>{
                console.log("email sent!");
            }).catch(()=>{
                console.log("error sending email")
            });
        }

        console.log("Document successfully written!");
        return true;
    }).catch(function(error:any) {
        console.error("Error writing document: ", error);
        return false;
    });

    return response;
}


export default function(){
    return <App state={store.getState()} getUsers={getUsers} setBlacklistStatus={setBlacklistStatus} getCompanies={getCompanies} setUserOrganisation={setUserOrganisation}></App>;
}