import React, { useEffect, useState, useRef } from "react";
import {Link} from "react-router-dom"
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faPlus} from '@fortawesome/free-solid-svg-icons';


function Dashboard(props:any){
    const [addAdminModal,setAddAdminModal] = useState(false);
    const [addOrgModal,setAddOrgModal] = useState(false);
    const [companies,setCompanies] = useState([]);
    const [name, setName] = useState("");


    const [message,setMessage] = useState({
        text:"",
        success:true,
        reveal:false,
    });

    useEffect(()=>{
        const getCompanies = async () => {
            const companies = await props.getCompanies();
            setCompanies(companies);
        }

        getCompanies();

        const getAdminInfo = async () => {
            const admin = await props.getAdminInfo(props.state.login.email);
            
            if(admin){
                const firstName = admin.name.split(" ")[0];
                setName(firstName);
            }
        }

        getAdminInfo();

    },[]);

    return(
        <>
            <div className="main__wrapper">
                <div className="text-wrapper text-admin">
                    <h1>{name ? "Välkommen " + name + "!" : ""}</h1>
                    <p>
                        Detta är den superhemliga adminpanelen! Här kan ni lägga till och ändra företagsnamn för organisationer samt lägga
                        till logotyp för varje organisation, vilket sedan visas när tillhörande användare loggar in.
                    </p>
                    <p>
                        Organisationer skapas automatiskt genom att en annons med ett nytt organisationsid skapas. 
                        All data uppdateras 1 gång per dag, ungefär kl 8:00 så nya annonser och samt organisationer dyker inte upp direkt.
                        Det går även att skapa organisationer direkt utan att skapa en annons för dom.
                    </p>
                    <p>
                        Under varje organisation listas dess användare och åtgärder kring tillgång till annonsplattformen kan ändras.
                    </p>
                    <div className="button-wrapper">
                        <Link className="button" to={"/admin/users/"}>
                            Visa alla användare
                        </Link>
                        <button className="button" onClick={()=>{setAddAdminModal(true)}}>
                            Lägg till admin
                        </button>
                    </div>
                </div>

                {companies.length > 0 && 
                    <>
                        <CompanyList companies={companies} setAddOrgModal={setAddOrgModal}></CompanyList>
                    </>
                }

                {addAdminModal && <AddAdminModal setModal={setAddAdminModal} addAdmin={props.addAdmin} setMessage={setMessage}></AddAdminModal>}
                {addOrgModal && <AddOrgModal setModal={setAddOrgModal} companies={companies} addOrg={props.addOrganisation} setMessage={setMessage} setCompanies={setCompanies}></AddOrgModal>}
                {message.reveal && 
                    <InfoMessage success={message.success} message={message.text} setMessage={setMessage}></InfoMessage>
                }

            </div>
        </>
    );
}



function CompanyList(props:any){
    return (
        <>
            {props.companies.length > 0 && 
                <>
                    
                    <div className="userlist">
                        <div className="userlist_title">
                            <h2>Hantera Organisationer</h2>
                            <button className="button" onClick={()=>{props.setAddOrgModal(true)}}>
                                <FontAwesomeIcon icon={faPlus} />
                                Lägg till
                            </button>
                        </div>
                        <ul className="userlist_list-body">
                            {props.companies.map((company:any,index:number)=>{
                                return(
                                    <CompanyListItem key={index} company={company}/>
                                )
                            })}
                        </ul>
                    </div>                                
                </>
            }
        </>
    );
}


function CompanyListItem(props:any){

    return(
        <li className="list-item">
            <div className="list-item-wrapper">
                <div className="list-item-content">
                    <span className="item">
                        <label>Id</label>
                        <span className="list-item-value">{props.company.id}</span>
                    </span>
                    <span className="item width-250px">
                        <label>Namn</label>
                        <span className="list-item-value">{props.company.name ? props.company.name : "Namn saknas"}</span>
                    </span>
                    <span className="item">
                        <Link to={"/admin/details/"+ props.company.id}>
                            <button className="button" onClick={()=>{}}>
                                Visa
                            </button>
                        </Link>
                    </span>
                </div>
            </div>
        </li>
    );
}


function AddAdminModal(props:any){
    const node:any = useRef();
    const modalRef:any = useRef();
    const [email,setEmail] = useState("");
    const [name,setName] = useState("");

    const [emailError,setEmailError] = useState(false);
    const [nameError,setNameError] = useState(false);

    const handleClick = (event:any) => {
        if (node.current.contains(event.target)) {
          // inside click
          return;
        }
        // outside click 
        closeModal();
    };

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const closeModal = () => {
        modalRef.current.classList.add("hide__modal");
        setTimeout(()=>{
            props.setModal(false);
        },300);
    }

    const addAdmin = async () => {

        let error = false;

        if(!email){
            error = true;
            setEmailError(true);
        }

        if(!name){
            error = true;
            setNameError(true);
        }

        if(error){
            return;
        }

        //Add user to admin
        const response = await props.addAdmin(email,name);
        
        const newMessage = {
            text:(response ? "Administratör lades till!":"Något gick fel, försök igen."),
            success:response,
            reveal:true,
        }
        
        props.setMessage(newMessage);

        closeModal();
    }



    useEffect(()=>{
        if(email){
            setEmailError(false);
        }
    },[email]);

    useEffect(()=>{
        if(name){
            setNameError(false);
        }
    },[name]);


    return(
        <div className="modal" ref={modalRef}>
            <div className="modal__container" ref={node}>
                <h3>Lägg till admin</h3>
                <form className="form" onSubmit={(e)=>{e.preventDefault(); addAdmin()}}>
                    <div className={"input-wrapper" + (email !== "" ? " focus" : "") + (emailError ? " error" : "")}>
                        <input placeholder="&nbsp;" type="text" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} autoComplete='on'/>
                        <label>Email</label>
                    </div>
                    <div className={"input-wrapper" + (name !== "" ? " focus" : "") + (nameError ? " error" : "")}>
                        <input placeholder="&nbsp;" type="text" value={name} onChange={(e) => {setName(e.currentTarget.value)}} autoComplete='on'/>
                        <label>Namn</label>
                    </div>
                    <button type="submit" className="button">
                        Lägg till
                    </button>
                </form>
            </div>
        </div>
    );
}



function AddOrgModal(props:any){
    const node:any = useRef();
    const modalRef:any = useRef();
    const [id,setId] = useState("");
    const [name,setName] = useState("");

    const [idError,setIdError] = useState(false);

    const handleClick = (event:any) => {
        if (node.current.contains(event.target)) {
          // inside click
          return;
        }
        // outside click 
        closeModal();
    };

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const closeModal = () => {
        modalRef.current.classList.add("hide__modal");
        setTimeout(()=>{
            props.setModal(false);
        },300);
    }

    const addOrg = async () => {

        let error = false;

        if(!id){
            error = true;
            setIdError(true);
        }

        //Check if id already exist
        for(let i = 0; i < props.companies.length; i++){
            if(id.toUpperCase() === props.companies[i].id){
                error = true;
                setIdError(true);
            }
        }

        if(error){
            return;
        }

        //Add user to admin
        const response = await props.addOrg(id,name);

        if(response){
            //If it is a positive response then update the company array without making additional calls to the database.
            props.setCompanies((companies:any)=>{
                const newCompany = {
                    id:id.toUpperCase().trim(),
                    name:name.trim(),
                }

                //Add the new company
                companies.push(newCompany);

                //Sort alphabetically by id
                companies.sort((a:any, b:any)=>{
                    var x = a.id.toLowerCase();
                    var y = b.id.toLowerCase();
                    if (x < y) {return -1;}
                    if (x > y) {return 1;}
                    return 0;
                });

                return [...companies];
            })
        }


        const newMessage = {
            text:(response ? "Organisation lades till!":"Något gick fel, försök igen."),
            success:response,
            reveal:true,
        }
        
        props.setMessage(newMessage);

        closeModal();
    }



    useEffect(()=>{
        if(id){
            setIdError(false);
        }
    },[id]);


    return(
        <div className="modal" ref={modalRef}>
            <div className="modal__container" ref={node}>
                <h3>Lägg till organisation</h3>
                <form className="form" onSubmit={(e)=>{e.preventDefault(); addOrg()}}>
                    <div className={"input-wrapper" + (id !== "" ? " focus" : "") + (idError ? " error" : "")}>
                        <input placeholder="&nbsp;" type="text" value={id} onChange={(e) => {setId(e.currentTarget.value)}} autoComplete='on'/>
                        <label>Id</label>
                    </div>
                    <div className={"input-wrapper" + (name !== "" ? " focus" : "")}>
                        <input placeholder="&nbsp;" type="text" value={name} onChange={(e) => {setName(e.currentTarget.value)}} autoComplete='on'/>
                        <label>Namn</label>
                    </div>
                    <button type="submit" className="button">
                        Lägg till
                    </button>
                </form>
            </div>
        </div>
    );
}




function InfoMessage(props:any){

    const infoRef:any = useRef();

    useEffect(()=>{
        

        setTimeout(()=>{

            if(!infoRef.current){
                return;
            }

            infoRef.current.classList.add("remove");

            setTimeout(()=>{
                props.setMessage((message:any)=>{
                    const newMessage = {
                        text:message.text,
                        success:message.success,
                        reveal:false,
                    }
    
                    return newMessage;
                });
            },200)

        },2000)

    },[]);

    return(
        <div className="popup-message" ref={infoRef}>
            <span className={"symbol" + (props.success ? " success" : " fail")}>
                {props.success ? 
                    <FontAwesomeIcon icon={faCheckCircle} />:
                    <FontAwesomeIcon icon={faExclamationCircle} /> 
                } 
            </span>
            <span className="message">              
                {props.message}
            </span>     
        </div>
    );
}


export default Dashboard;