import React from 'react';
import {store, RenderApp, db} from '../../index';
import App from './Dashboard';
//import {getAccountInsights} from "../../common/marketingAPI";
import {getCampaigns} from "../../common/marketingAPI/campaignInfo";


/*Is called when logging in*/
export async function fetchStats(){
    /*const interval = store.getState().dashboard.interval;
    await getAccountInsights("act_874423135977018",interval);//Tross kundkonto
    RenderApp();*/
    await getCampaigns();
    RenderApp();
    return null;
}

/*Are called when changing interval on insights*/
/*async function fetchAccountInsights(){
    const interval = store.getState().dashboard.interval;
    await getAccountInsights("act_874423135977018",interval);//Tross kundkonto
}*/


async function hideCampaign(id:string,hidden:boolean){

    const response = await db.collection("campaigns").doc(id).set({
        hidden:hidden,
    },{ merge: true }).then(function() {
        console.log("Document successfully written!");
        return true;
    }).catch(function(error:any) {
        console.error("Error writing document: ", error);
        return false;
    });

    return response;
    
}



export default function(){
    return <App state={store.getState()} fetchStats={fetchStats} hideCampaign={hideCampaign}></App>;
}