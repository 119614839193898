const defaultState = {
    ads:{
        data:[],
        next:"",
    },
    adInfo:null,
    target:null,
    adPreview:null,
};

export default function(state = defaultState, action:any){

    if(action.type === "SET_ADS"){
        return { ...state, ads: action.ads };
    }

    if(action.type === "AD_INFO"){
        return { ...state, adInfo: action.adInfo };
    }

    if(action.type === "SET_PREVIEW"){
        return { ...state, adPreview: action.adPreview };
    }

    if(action.type === "SET_TARGET"){
        return { ...state, target: action.target };
    }

    return state;
};