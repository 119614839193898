import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Link} from "react-router-dom";
import {validateEmail} from '../../common/emailValidation';
import {dbAuth,db} from '../../index';
import "./style.scss";

function App(props:any){
    const loginRef:any = useRef();
    const emailErrorRef:any = useRef();
    const pwErrorRef:any = useRef();

    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError,setEmailError] = useState(false);
    const [pwError,setPwError] = useState(false);


    useEffect(()=>{

        let previousLocation = ""; 
        if(props.location.state){
            previousLocation = props.location.state.previousLocation;
        }


        dbAuth.onAuthStateChanged(async function(user:any) {
            if (user) {//Set state to logged in if user already is and fetch statistics        

                if(user.emailVerified){

                    if(loginRef.current){
                        loginRef.current.classList.add("loading");
                    }
                    
                    //Update login variable
                    db.collection('users').doc(user.email).set({
                        has_logged_in: true,
                    },{merge:true});

                    await props.updateLoginState(user.email);
                    if(previousLocation){
                        props.history.push(previousLocation);
                    }else{
                        props.history.push('/dashboard/');
                    }




                    
                }else{
                    dbAuth.signOut().then(function() {

                        if(loginRef){
                            loginRef.current.classList.remove("loading");
                        }

                        // Sign-out successful.
                        setEmailError(true);
                        emailErrorRef.current.innerHTML = "Du måste verifiera din mailaddress för att logga in";
                        emailErrorRef.current.classList.add("visible");
    
                    }).catch(function(error:any) {
                        // An error happened.
                    });
                }        
        
            }
        });
    
    },[]);



    const login = () => {

        let error = false;

        if(!email){
            //fieldError("emailField","Fältet får inte vara tomt");
            emailErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setEmailError(true);
            error = true;
        }else if(!validateEmail(email)){
            //fieldError("emailField","Ogiltig mailaddress");
            emailErrorRef.current.innerHTML = "Ogiltig mailaddress";
            setEmailError(true);
            error = true;
        }
    
        if(!password){
            //fieldError("passwordField","Fältet får inte vara tomt");
            pwErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setPwError(true);
            error = true;
        }
    
        if(error){
            return;
        }

        loginRef.current.classList.add("loading");

        dbAuth.signInWithEmailAndPassword(email.trim(),password.trim()).then(async function() {
            // Sign-in successful
        }).catch(function(error:any){
            if(!loginRef.current){
                return;
            }

            loginRef.current.classList.remove("loading");

            if(error.code === "auth/network-request-failed"){
                setPwError(true);
                pwErrorRef.current.innerHTML = "Kunde inte koppla nå nätverket.";
            }else if(error.code === "auth/user-disabled"){
                setPwError(true);
                pwErrorRef.current.innerHTML = "Kontot har blivit spärrat";

                fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/send_email?email=${email}`).then(()=>{
                    //Email sent
                });

            }else{
                setPwError(true);
                pwErrorRef.current.innerHTML = "Ogiltiga kontouppgifter";
            }
        });

    }

    useEffect(()=>{
        setEmailError(false);
    },[email]);

    useEffect(()=>{
        setPwError(false);
    },[password]);


    return(
        <>
            <div className="login-container">
                <h1>Inloggning</h1>
                <form className="form" onSubmit={(e) => {e.preventDefault(); login()}}>
                    <div className={"input-wrapper" + (emailError ? " error" : "")}>
                        <input placeholder="Email" type="text" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message error-message">
                            <p ref={emailErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <div className={"input-wrapper" + (pwError ? " error" : "")}>
                        <input placeholder="Lösenord" type="password" value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} autoComplete='on'/>
                        <div className="message error-message">
                            <p ref={pwErrorRef}>Fältet får inte vara tomt</p>
                        </div>
                    </div>
                    <button className="button" type="submit" ref={loginRef}>
                        Logga in
                    </button>
                </form>
                <div className="login-footer">
                    <p>
                        <Link to="/resetpassword">
                            <button className="button-text">
                                Återställ lösenord
                            </button>
                        </Link> 
                        <span className="separator">eller</span> 
                        <Link to="/createaccount">
                            <button className="button-text">
                                Skapa konto
                            </button>
                        </Link>
                        </p>
                </div>
            </div>
        </>
    );

}



export default withRouter(App);
