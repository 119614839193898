import React from 'react';
import {withRouter,Link} from "react-router-dom";
import Message404 from "../Message404";
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

function AdList(props:any){

    return(
        <>
        <div className="list-container-wrapper">
            <div className="list-container">
                <div className="list-title">
                    Annonser
                </div>
                <div className="list-overflow-wrapper">
                    <div className="list-content">
                        <div className="list-header">
                            <div className="list-item-wrapper">
                                <span className="item width-fixed-150px">Id</span>
                                <span className="item width-auto">Titel</span>
                                <span className="item width-fixed-120px">Status</span>
                                {/*<span className="item width-fixed-120px">Skapat</span>*/}
                            </div>
                        </div>
                        <ul className="list-body">
                            {props.posts.data.length > 0 &&
                                props.posts.data.map((post:any,index:number)=>{
                                    return(
                                    <ListItem post={post} key={index} viewAd={props.viewAd} history={props.history}></ListItem>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
                {props.posts.data.length === 0 && <Message404 message={"Inga annonser hittades"} />}
            </div>
            {props.posts.next &&
                <button className="button" onClick={(e)=>{e.preventDefault(); props.getAds();}}>
                    Ladda fler
                </button>
            }
        </div>
        </>
    );
}
  
  
function ListItem(props:any){
    return(
        <li className="list-item">
            <div className="list-item-wrapper">
                <span className="item width-fixed-150px">{props.post.id}</span>
                <span className="item width-auto">
                    <Link to={"/dashboard/advert/"+props.post.id}>
                        <button className="button-text">{props.post.name}</button>
                    </Link>
                </span>
                <span className="item width-fixed-120px">
                    <span className={"status " + props.post.status}>
                        {props.post.status === "ACTIVE" && "Aktiv"}
                        {props.post.status === "UNCOMPLETE" && "Ej aktiv"}
                        {props.post.status === "PAUSED" && "Pausad"}
                        {props.post.status === "DONE" && "Avklarad"}
                    </span>
                </span>
                <div className="item width-fixed-150px">
                    <Link to={"/dashboard/advert/"+props.post.id}>
                        <button className="button go-to-button">
                            Gå till Annons <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    </Link>
                </div>
                {/*<span className="item width-fixed-120px">{formatDate(new Date(props.post.created_time))}</span>*/}
            </div>
        </li>
    );
}

  
  export default withRouter(AdList);