import React, { useEffect, useState, useRef } from "react";
import {Link,withRouter} from "react-router-dom"
import "./style.scss";
import{formatDate} from "../../../../common/formatHelper";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faUpload, faCheckCircle, faExclamationCircle, faThumbsDown, faThumbsUp, faTrash, faLock, faUnlock} from '@fortawesome/free-solid-svg-icons';
import {storage} from '../../../../index';

function Dashboard(props:any){

    const logoRef:any = useRef();

    const [companyInfo,setCompanyInfo] = useState();
    const [users,setUsers] = useState([]);
    const [name,setName] = useState("");
    const [newName,setNewName] = useState("");
    const [logo,setLogo] = useState("");
    

    const [message,setMessage] = useState({
        text:"",
        success:true,
        reveal:false,
    });


    useEffect(()=>{

        if(props.match.params.id){
            const id = props.match.params.id;

            const getCompanyInfo = async (company_id:string) => {
                const response = await props.getCompanyInfo(company_id);
                setCompanyInfo(response);

                if(response.name){
                    setName(response.name);
                    setNewName(response.name);
                }

                logoRef.current.classList.add("loading");
                const logo = await props.getLogo(id);
                logoRef.current.classList.remove("loading");
                setLogo(logo);
            }

            const getUsers = async (company_id:string) => {
                const response = await props.getUsers(company_id);
                setUsers(response);
            }

            getCompanyInfo(id);
            getUsers(id);
        
        }

    },[]);


    function uploadImage(files:any, company_id:string){
        // Create a root reference
        let file;
        
        if(files[0]){
            file = files[0];
        }else{
            return;
        }
    
        //Get filetype .png, .jpg etc
        const fileType = file.name.split(".")[1];
    
        //Create storage ref
        const storageRef = storage.ref("logos/" + company_id + "." + fileType);
        
        //Upload file
        const task = storageRef.put(file);
    
        logoRef.current.classList.add("loading");

        task.on('state_changed',
        
            function progress(snapshot:any){
                const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
    
            function error(err:any){
                logoRef.current.classList.remove("loading");

                const newMessage = {
                    text:"Något gick fel med uppladdningen",
                    success:false,
                    reveal:true,
                }
                setMessage(newMessage);

            },
            function complete(){
                //Get organisation background
                storage.ref("logos/" + company_id + "." + fileType).getDownloadURL().then(function(url:any) {


                    var newImg = new Image;

                    newImg.onload = () => {
                        logoRef.current.classList.remove("loading");
                        setLogo(url);
                        const newMessage = {
                            text:"Logotyp uppdaterad!",
                            success:true,
                            reveal:true,
                        }
                        setMessage(newMessage);
                        return null;
                    }
                    
                    newImg.src = url;

                    return null;
                });
            }
        );
    
    }

    const saveName = async (company_id:string,company_name:string) => {

        const response = await props.saveName(company_id,company_name);

        if(response){
            const newMessage = {
                text:"Organisationsnamn sparat!",
                success:true,
                reveal:true,
            }
            setMessage(newMessage);
            setName(newName);
        }else{
            const newMessage = {
                text:"Något gick fel!",
                success:false,
                reveal:true,
            }
            setMessage(newMessage);
        }

    }

    const resetChanges = () => {
        setNewName(name);
    }

    const deleteLogo = () => {
        if(props.deleteLogo(companyInfo.id)){
            const newMessage = {
                text:"Logotyp borttagen",
                success:true,
                reveal:true,
            }
            setMessage(newMessage);
            setLogo("");
        }else{
            const newMessage = {
                text:"Kunde inte ta bort bilden.",
                success:false,
                reveal:true,
            }
            setMessage(newMessage);
        }
    }

    return(
        <>
            <div className="main__wrapper">
                {companyInfo &&
                    <div className="breadcrumbs-wrapper">
                        <p className="breadCrumbs">
                            <span><Link to="/admin/dashboard/">Överblick</Link></span>
                            {companyInfo.id && 
                                <span><Link to={"/admin/details/"+companyInfo.id}>{companyInfo.id}</Link></span>
                            }
                        </p>
                    </div>
                }

                {companyInfo &&
                    <div className="text-wrapper">
                        {message.reveal && 
                            <InfoMessage success={message.success} message={message.text} setMessage={setMessage}></InfoMessage>
                        }
                        <form className="form company-form" onSubmit={(e) => {e.preventDefault(); saveName(companyInfo.id,newName)}}>
                            <div className={"input-wrapper" + (newName !== "" ? " focus" : "")}>
                                <input placeholder="&nbsp;" type="text" value={newName} onChange={(e) => {setNewName(e.currentTarget.value)}} autoComplete='on'/>
                                <label>Organisationsnamn</label>
                            </div>
                            <div className="form_actions">
                                <button className="button-text" type="button" onClick={(e)=>{e.preventDefault(); resetChanges()}}>Återställ</button>
                                <button className="button-text" type="submit">Spara</button>
                            </div>
                        </form>
                        <div className="file-upload">
                            {logo ? 
                                <div className="logo-wrapper" ref={logoRef}>
                                    <figure>
                                        <img src={logo}></img>
                                        <button className="logo-delete" onClick={deleteLogo}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </figure>
                                </div>
                                :
                                <div className="logo-preview" ref={logoRef}></div>
                            }
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <div className="button">
                                    <FontAwesomeIcon icon={faUpload} />
                                    {logo ? "Uppdatera logga" : "Ladda upp logga"}
                                </div>
                            </label>
                            <input id="file-upload" type="file" accept="image/x-png" onChange={(e)=>{uploadImage(e.target.files,companyInfo.id)}}/>
                        </div>
                    </div>
                }

                <UserList users={users} updatePriviledge={props.updatePriviledge} setBlacklistStatus={props.setBlacklistStatus} setMessage={setMessage}></UserList>
            </div>
        </>
    );
}

function InfoMessage(props:any){

    const infoRef:any = useRef();

    useEffect(()=>{
        

        setTimeout(()=>{

            if(!infoRef.current){
                return;
            }

            infoRef.current.classList.add("remove");

            setTimeout(()=>{
                props.setMessage((message:any)=>{
                    const newMessage = {
                        text:message.text,
                        success:message.success,
                        reveal:false,
                    }
    
                    return newMessage;
                });
            },200)

        },2000)

    },[]);

    return(
        <div className="popup-message" ref={infoRef}>
            <span className={"symbol" + (props.success ? " success" : " fail")}>
                {props.success ? 
                    <FontAwesomeIcon icon={faCheckCircle} />:
                    <FontAwesomeIcon icon={faExclamationCircle} /> 
                } 
            </span>
            <span className="message">              
                {props.message}
            </span>     
        </div>
    );
}

function UserList(props:any){
    return (
        <>
            {props.users.length > 0 && 
                <>
                    
                    <div className="userlist">
                        <div className="userlist_title">
                            <h2>Hantera användare</h2>
                        </div>
                        <ul className="userlist_list-body">
                            {props.users.map((user:any,index:number)=>{
                                return(
                                    <UserListItem key={index} user={user} updatePriviledge={props.updatePriviledge} setBlacklistStatus={props.setBlacklistStatus} setMessage={props.setMessage}/>
                                )
                            })}
                        </ul>
                    </div>                                
                </>
            }
        </>
    );
}


function UserListItem(props:any){

    const [blacklistStatus,setBlacklistStatus] = useState(props.user.blacklisted ? props.user.blacklisted : props.user.disabled);
    const [permission,setPermission] = useState((props.user.permission ? props.user.permission : false));

    const updateBlacklistStatus = () => {
        let blacklisted = blacklistStatus;
        if(blacklisted){
            blacklisted = !blacklisted;
        }else{//if blacklist status is null
            blacklisted = true;
        }

        setBlacklistStatus(blacklisted);

        if(props.setBlacklistStatus(props.user.email,blacklisted)){
            //TODO update message
            const newMessage = {
                text:(blacklisted ? "Användare svartlistad!" : "Borttagen från svartlistan!"),
                success:true,
                reveal:true,
            }
            props.setMessage(newMessage);
        }
    }

    const updatePriviledges = async () => {

        const response = await props.updatePriviledge(props.user.email, !permission);

        if(response){
            setPermission((permission:any)=>{
                return !permission;
            });
        }

        const newMessage = {
            text:(response ? "Tillgång ändrad!" : "Något gick fel."),
            success:response,
            reveal:true,
        }
        props.setMessage(newMessage);

    }

    return(
        <li className="list-item">
            <div className="list-item-wrapper">
                <div className="list-item-content">
                    <span className="item width-250px">
                        <label>Email</label>
                        <span className="list-item-value">{props.user.email}</span>
                    </span>
                    <span className="item">
                        <label>Aktiverat konto</label>
                        <span className="list-item-value">{props.user.uid ? "Ja" : "Nej"}</span>
                    </span>
                    <span className="item">
                        <label>Senast inloggad</label>
                        <span className="list-item-value">{props.user.lastSignInTime ? formatDate(new Date(props.user.lastSignInTime)) : "Aldrig"}</span>
                    </span>
                    <span className="item">
                        <label>Organisationsid</label>
                        <span className="list-item-value">{props.user.company_id ? props.user.company_id : "Saknas"}</span>
                    </span>
                    <span className="item">
                        <label>Kontot skapat</label>
                        <span className="list-item-value">{props.user.creationTime ? formatDate(new Date(props.user.creationTime)) : formatDate(new Date(props.user.created_date))}</span>
                    </span>
                </div>
                <div className="list-actions">
                    {!blacklistStatus ? 
                        <button className="button blacklist" onClick={updateBlacklistStatus}>
                            <FontAwesomeIcon icon={faThumbsDown} /> 
                            Svartlista
                        </button>:
                        <button className="button whitelist" onClick={updateBlacklistStatus}>
                            <FontAwesomeIcon icon={faThumbsUp} /> 
                            Vitlista
                        </button>
                    }

                    {(!permission ? 
                        <button className="button" onClick={()=>{updatePriviledges()}}>
                            <FontAwesomeIcon icon={faUnlock} /> 
                            Ge full tillgång
                        </button>:
                        <button className="button blacklist" onClick={()=>{updatePriviledges()}}>
                            <FontAwesomeIcon icon={faLock} /> 
                            Ta bort full tillgång
                        </button>)
                    }
                </div>
            </div>
        </li>
    );
}




export default withRouter(Dashboard);